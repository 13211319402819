import {
  StepSevenTypes,
  agreementTypes,
  stepFiveTypes,
  stepFourTypes,
  stepSixTypes,
  stepThreeTypes,
  stepTwoTypes,
} from "utils/types";
import * as Yup from "yup";

export const steponeSchema: Yup.Schema<{ studentId: string }> =
  Yup.object().shape({
    studentId: Yup.string()
      .required("studentId is required")
      .matches(/^[a-zA-Z0-9\s]+$/, "Please enter only digits and letters"),
  });

export const agreementSchema: Yup.Schema<agreementTypes> = Yup.object().shape({
  isDataprocess: Yup.boolean().required("Please accept terms"),
  isImmigration: Yup.boolean().required("Please accept terms"),
  isAttorney: Yup.boolean().required("Please accept terms"),
  startDate: Yup.date().required("Date is required"),
  signature: Yup.string().required("Signature is required"),
});

export const submitSchema: Yup.Schema = Yup.object().shape({
  // date : Yup.date().required("Date is required"),
  signature: Yup.string().required("Signature is required"),
});

export const steptwoSchema: Yup.Schema<stepTwoTypes> = Yup.object().shape({
  lastName: Yup.string()
    .test(
      "contains-letters",
      "At least one letter is required",
      (value: any) => {
        return /[a-zA-Z]/.test(value);
      }
    ),
  firstName: Yup.string()
    .required("First name is required")
    .test("contains-letters", "At least one letter is required", (value) => {
      return /[a-zA-Z]/.test(value);
    }),
  middleName: Yup.string()
    .test(
      "contains-letters",
      "At least one letter is required",
      (value: any) => {
        return /[a-zA-Z]/.test(value);
      }
    ),
  gender: Yup.string().required("Gender is required"),
  city: Yup.string().required("City is required"),
  zipcode: Yup.string()
    .required("Zipcode is required")
    .matches(/^\d+$/, "Zipcode must contain only digits")
    .max(10, "Zipcode must be at most 10 characters"),
  address: Yup.string()
    .required("Address is required")
    .test(
      "contains-letters",
      "At least one letter is required",
      (value: any) => {
        return /[a-zA-Z]/.test(value);
      }
    ),
  houseNo: Yup.string().required("House number is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .min(7, "Phone number should not less than 7 digits"),
  dob: Yup.date().required("Birthdate is required"),
  birthPlace: Yup.string().required("Birthplace is required"),
  birthCountry: Yup.string().required("Birthcountry is required"),
  region: Yup.string().required("Region is required"),
  addressRegister: Yup.string().required("Address register is required"),
  registerDate: Yup.string().test({
    name: "requiredWhenAddressRegister",
    message: "Register date is required",
    test: function (value) {
      return !!(this.parent.addressRegister === "Yes") ? !!value : true;
    },
  }),
  addressFile: Yup.mixed().test({
    name: "requiredWhenAddressRegister",
    message: "Files are required",
    test: function (value: any) {
      if (value) {
        return this.parent.addressRegister === "Yes" ? value.length : true;
      }
    },
  }),
});

export const stepthreeSchema: Yup.Schema<stepThreeTypes> = Yup.object().shape({
  file: Yup.array()
    .required("Passport file is required")
    .min(1, "Files are required")
    .max(20, "You cannot upload more than 20 files"),
  docType: Yup.string().required("Document type is required"),
  authority: Yup.string().required("Authority is required"),
  docNumber: Yup.string().required("Document Number is required"),
  issueDate: Yup.date().required("Issue date is required"),
  validDate: Yup.date().required("Valid date is required"),
});

export const stepFourSchema: Yup.Schema<stepFourTypes> = Yup.object().shape({
  stampFile: Yup.mixed()
    .test({
      name: "is-array-if-required",
      message: "Files are required",
      test: function (value: any) {
        if (value) {
          return value.length === 0 ? value.length : true;
        }
      },
    })
    .required("Files are required"),
  date: Yup.date().required("Date is required"),
  lastEntrycity: Yup.string().required("Country is required"),
});

export const stepFiveSchema: Yup.Schema<stepFiveTypes> = Yup.object().shape({
  citizinShip: Yup.string().required("Citizenship is required"),
  additionalCitizinShip: Yup.string().required(
    "Please select additional citizenship"
  ),
  citizinShip1: Yup.string().test({
    name: "requiredWhenadditionalCitizinShip",
    message: "Please select at least one citizenship",
    test: function (value) {
      console.log(
        "sdss",
        !this.parent.citizinShip2 && !this.parent.citizinShip3
      );
      return !!(
        this.parent.additionalCitizinShip === "Yes" &&
        !this.parent.citizinShip2 &&
        !this.parent.citizinShip3
      )
        ? !!value
        : true;
    },
  }),
});

export const stepSixSchema: Yup.Schema<stepSixTypes> = Yup.object().shape({
  status: Yup.string().required("Status is required"),
  lastEntry: Yup.date().required("Last entry date is required"),
  lastExit: Yup.string().required("Last exit  date required"),
  passportFile: Yup.array()
    .required("Files are required")
    .min(1, "Files are required"),
});

export const stepSevenSchema: Yup.Schema<StepSevenTypes> = Yup.object().shape({
  germanLastEntry: Yup.string().required("German last entry is required"),
  authorisation: Yup.string().required("Authorisation is required"),
  residence: Yup.string().required("Residence is required"),
  maritalStatus: Yup.string().required("Status is required"),
  children: Yup.string().required("Please select one field"),
  father: Yup.string().required("Please select one field"),
  mother: Yup.string().required("Please select one field"),
  criminality: Yup.string().required("Please select one field"),
  germanyRejection: Yup.string().required("Please select one field"),
  otherRejection: Yup.string().required("Please select one field"),
  subsistence: Yup.string().required("Please select one field"),
  subsistenceFile: Yup.mixed().test({
    name: "requiredWhensubsistence",
    message: "Files are required",
    test: function (value: any) {
      if (value) {
        return this.parent.subsistence === "Yes" ? value.length : true;
      }
    },
  }),
  healthInsurance: Yup.string().required("Please select one field"),
  healthInsuranceFile: Yup.mixed().test({
    name: "requiredWhenhealthInsurance",
    message: "Files are required",
    test: function (value: any) {
      if (value) {
        return this.parent.healthInsurance === "Yes" ? value.length : true;
      }
    },
  }),
  matriculationLetter: Yup.string().required("Please select one field"),
  date: Yup.string().test({
    name: "requiredWhenMatriculationLetter",
    message: "Date is required",
    test: function (value) {
      return !!(this.parent.matriculationLetter === "Yes") ? !!value : true;
    },
  }),
  matriculationFile: Yup.mixed().test({
    name: "requiredWhenmatriculationLetter",
    message: "Files are required",
    test: function (value: any) {
      if (value) {
        return this.parent.matriculationLetter === "Yes" ? value.length : true;
      }
    },
  }),
});

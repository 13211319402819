import { ErrorMessage, Field } from "formik";
import { inputPropsTypes } from "utils/types";

const Input: React.FC<inputPropsTypes> = ({
  type,
  fieldName,
  placeholder,
  setValue,
  className,
  handleChange,
  errorText,
}) => {
  return (
    <>
      <div className="mb-2 md:mb-4">
        <Field
          type={type}
          name={fieldName}
          className={
            className
              ? className
              : "password-input border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px]"
          }
          value={setValue}
          placeholder={placeholder}
          onChange={handleChange}
        />
        {errorText ? (
          <div className="text-red-500">{errorText}</div>
        ) : (
          <ErrorMessage
            name={fieldName}
            component="div"
            className="text-red-500 text-sm md:text-base"
          />
        )}
      </div>
    </>
  );
};

export default Input;

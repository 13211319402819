import {
  Button,
  CustomDateInput,
  Inputfile,
  RadioGroup,
} from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { stepSixSchema } from "schema/Prestudy";
import { statusOptions, visaOptions } from "utils/data";
import { stepProps, stepSixTypes } from "utils/types";
import DatePicker from "react-datepicker";
import { useAppSelector } from "store/Hooks";
import { prestudySelector } from "store/slices/Prestudy";
import { crossBlack, documentBlack } from "utils/Images";
import { validateFiles } from "utils/Helper";
import moment from "moment";
import { userSelector } from "store/slices/User";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";

const StepSix: React.FC<stepProps> = ({ submitStepdata }) => {
  const [fileError, setfileError] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<File[] | null>(null);
  const { user } = useAppSelector(userSelector);
  const [minDate, setMindate] = useState<Date | null>(null);
  const { currentStep, stepData, citizenShip } =
    useAppSelector(prestudySelector);
  const [lastEntryDays, setLastEntryDays] = useState<number>(0);
  const [lastEntryExitDays, setLastEntryExitDays] = useState<number>(0);

  const [stepFields, setStepFields] = useState<stepSixTypes>({
    visa: "",
    status: "",
    lastEntry: null,
    lastExit: null,
    passportFile: [],
  });
  const initialValues: stepSixTypes = {
    visa: stepFields.visa ? stepFields.visa : "",
    status: stepFields.status ? stepFields.status : "",
    lastEntry: stepFields.lastEntry ? stepFields.lastEntry : null,
    lastExit: stepFields.lastExit ? stepFields.lastExit : null,
    passportFile: stepFields.passportFile ? stepFields.passportFile : [],
  };
  const [leftDays, setLeftDays] = useState<number>(0);
  const [expirydate, setExpriyDate] = useState<string | "">("");
  const DateInput = DatePicker as any;
  const DateRangeInput = DateRangePicker as any;
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const [dateRange, setDateRange] = useState<any>(null);

  const handleSubmit = async (values: stepSixTypes) => {
    if (!fileError) {
      const formData = new FormData();
      if (!values?.passportFile[0].hasOwnProperty("location")) {
        values?.passportFile?.forEach((file: any) => {
          formData.append("passportFile", file);
        });
        user && formData.append("userId", user?._id);
        currentStep && formData.append("step", (currentStep - 1).toString());
        formData.append(
          "label",
          "Upload ALL the pages of your passport proving your entries and exits from Schengen area"
        );
      }
      let payload = [
        {
          name: "status",
          label: "Select the legal status you have at this moment",
          value: values.status,
          isShow: true,
        },
        {
          name: "lastEntry",
          label: "Last Entry",
          value: new Date(
            moment(values.lastEntry).format("YYYY/MM/DD")
          ).toISOString(),
          isShow: true,
        },
        {
          name: "lastExit",
          label: "Entry Exit 1",
          value: values.lastExit,
          isShow: true,
        },
        {
          name: "leftDays",
          label: "Day left on Schengen area",
          value: leftDays,
          isShow: true,
        },
        {
          name: "expiryDate",
          label: "Days period expiry date",
          value: expirydate,
          isShow: true,
        },
        {
          name: "lastEntryExitDays",
          label: "Entry exit Days",
          value: lastEntryExitDays,
          isShow: true,
        },
        {
          name: "lastEntryDays",
          label: "Last Entry days",
          value: lastEntryDays,
          isShow: true,
        },
      ];
      await submitStepdata(
        payload,
        !values?.passportFile[0].hasOwnProperty("location") ? formData : null
      );
    }
  };

  const uploadFiles = async (selectedFile: FileList | null) => {
    const allowedTypes = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
    const isValidate = await validateFiles(selectedFile, allowedTypes);
    if (isValidate === "true") {
      if (selectedFile) {
        setFiles(Array.from(selectedFile));
      } else {
        setFiles(null);
        setfileError(isValidate);
      }
    }
  };


  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof stepSixTypes;
      switch (name) {
        case "status":
        case "lastExit":
          updatedFields[name] = item.value;
          break;
        case "lastEntry":
          if (typeof item.value === "string") {
            updatedFields[name] = moment(item.value).format("YYYY-MM-DD");
          }
          break;
        case "passportFile":
          updatedFields[name] =
            item.value instanceof Array ? [...item.value] : item.value;
          break;
        case "leftDays":
          setLeftDays(item.value);
          break;
        case "expirydate":
          setExpriyDate(item.value);
          break;
        case "lastEntryExitDays":
          setLastEntryExitDays(item.value);
          break;
        case "lastEntryDays":
          setLastEntryDays(item.value);
          break;
        default:
          break;
      }
    });
    setStepFields(updatedFields);
  }, [stepData]);


  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full">
      <label className="text-[#0B0D0F] font-semibold text-[20px]">
        Immigration status
      </label>
      <p className="text-[#71859A] leading-[27.36px] text-[14px] mt-5 lg:w-1/2 xl:w-1/2 w-full">
        This field is automatically selected as soon as you select your country
        of nationality{" "}
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={stepSixSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, values, handleChange }: any) => (
          <Form className="mx-auto" id={currentStep.toString()}>
            <div className="mb-2 mt-4 flex justify-between items-center">
              <label
                htmlFor={"status"}
                className="text-[#0B0D0F] font-semibold text-[16px]"
              >
                Restrictions (according to the Foreigner's Office)
                <span className="text-[#FF4444] ml-1">*</span>
              </label>
            </div>
            <Field name="visa">
              {({ field }: FieldProps) => (
                <div className="text-[#71859A]">
                  <RadioGroup
                    {...field}
                    fieldName="visa"
                    options={visaOptions}
                    className={"ml-3 text-[#71859A]"}
                    disabled={true}
                    checkedField={
                      citizenShip === "Germany"
                        ? "You DO NOT need an entry visa"
                        : "You need an entry visa"
                    }
                  />
                </div>
              )}
            </Field>
            <p className="my-5 text-[#71859A] text-[14px]">
              3: You can stay 90 days in any 180-day period within the Schengen
              area. In order to study in Germany, a Residence permit for stays
              of more than 3 months can be applied for after entering Germany
              without any German Study Visa. It must be applied for within the
              90 days visa free period. In case you have still applied and got
              your German Study Visa from your home country, just choose this
              option in the following questions.{" "}
            </p>
            <hr className="bg-[#E1E5EA]" />
            <div className="mb-2 mt-4 flex justify-between items-center">
              <label
                htmlFor={"status"}
                className="text-[#0B0D0F] font-semibold text-[16px]"
              >
                Select the legal status you have at this moment?
                <span className="text-[#FF4444] ml-1">*</span>
              </label>
            </div>
            <Field name="status">
              {({ field, form }: FieldProps) => (
                <div className="text-[#71859A] leading-7">
                  <RadioGroup
                    {...field}
                    fieldName="status"
                    options={statusOptions}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      form.setFieldValue("status", event.target.value)
                    }
                    className={"ml-3"}
                    checkedField={values.status}
                  />
                </div>
              )}
            </Field>
            <ErrorMessage
              name="status"
              component="div"
              className="text-red-500"
            />
            <hr className="bg-[#E1E5EA] my-5" />
            <p className="text-[#71859A] text-[14px]">
              Enter the most recent entry-exit first. For the last entry (first
              line), you can only select one date.
            </p>
            <p className="text-[#71859A] text-[14px]">
              For the previous entry-exit (all other lines), you must select 2
              dates. Make sure that the entry-exit match the stamps on your
              passport.{" "}
            </p>
            <div className="mb-2 mt-4 flex justify-between items-center">
              <label
                htmlFor={"entryexit"}
                className="text-[#0B0D0F] text-[14px]"
              >
                Enter your last entry and all the entries-exits in the Schengen
                area for the last 180 days
                <span className="text-[#FF4444] ml-1">*</span>
              </label>
            </div>
            <div className="flex xl:flex-row flex-col mx-8 gap-2 xl:gap-0">
              <label
                htmlFor={"lastEntry"}
                className="text-[#71859A] text-[14px]"
              >
                Last Entry
              </label>
              <div className="xl:ml-auto ml-0 xl:w-[42vh] 2xl:w-[60vh] w-full">
                <Field name="lastEntry">
                  {({ field, form }: FieldProps) => (
                    <DateInput
                      {...field}
                      customInput={<CustomDateInput isCalendar={false} />}
                      dateFormat="yyyy-MM-dd"
                      selected={field.value}
                      onChange={(date: Date) => {
                        const minus180Days = new Date(date);
                        minus180Days.setDate(minus180Days.getDate() - 180);
                        form.setFieldValue("lastEntry", date);
                        setMindate(minus180Days);
                      }}
                      className={"w-full py-0"}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="lastEntry"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="xl:ml-2 ml-0 xl:w-[42vh] 2xl:w-[60vh] w-full">
                <input
                  type="text"
                  name="lastEntryDays"
                  value={lastEntryDays}
                  placeholder="Days"
                  className="password-input border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px] focus:outline-none py-[12px]"
                />
              </div>
            </div>
            <div className="flex xl:flex-row flex-col mx-8 gap-2 xl:gap-0 mt-3">
              <label
                htmlFor={"lastExit"}
                className="text-[#71859A] text-[14px]"
              >
                Entry Exit 1
              </label>
              <div className="xl:ml-auto ml-0 xl:w-[42vh] 2xl:w-[60vh] w-full">
                <div
                  className={`flex border-[2px] border-[#E1E5EA] rounded-[12px] max-w-full  p-3 cursor-pointer ${
                    !dateRange ? "text-[#71859A]" : "text-black"
                  }`}
                  onClick={() => {
                    setOpen((prev) => !prev);
                  }}
                >
                  {dateRange ? dateRange : "YYYY-MM-DD"}
                </div>
                {open && (
                  <Field name="lastExit">
                    {({ field, form }: FieldProps) => (
                      <DateRangeInput
                        {...field}
                        ranges={[selectionRange]}
                        onChange={(ranges: any) => {
                          const formattedDateRange = `${moment(
                            ranges.selection.startDate
                          ).format("YYYY-MM-DD")} - ${moment(
                            ranges.selection.endDate
                          ).format("YYYY-MM-DD")}`;
                          form.setFieldValue("lastExit", formattedDateRange);
                          setDateRange(formattedDateRange);
                          const lastEntryDate = new Date(
                            moment(values.lastEntry).format("YYYY-MM-DD")
                          );
                          const lastEntryExitStartdate = new Date(
                            moment(ranges.selection.startDate).format(
                              "YYYY-MM-DD"
                            )
                          );
                          const lastEntryExitEnddate = new Date(
                            moment(ranges.selection.endDate).format(
                              "YYYY-MM-DD"
                            )
                          );
                          const differenceInMilliseconds =
                            lastEntryDate.getTime() -
                            lastEntryExitStartdate.getTime();
                          const differenceInDays =
                            differenceInMilliseconds / (1000 * 3600 * 24);
                          const differenceInMilliseconds1 =
                            lastEntryExitEnddate.getTime() -
                            lastEntryExitStartdate.getTime();
                          const differenceInDays1 =
                            differenceInMilliseconds1 / (1000 * 3600 * 24);
                          setLastEntryDays(differenceInDays);
                          setLastEntryExitDays(differenceInDays1);
                          let days =
                            90 - (differenceInDays + differenceInDays1);
                          setLeftDays(days > 0 ? days : 0);
                          const inputDateString = moment(
                            values.lastEntry
                          ).format("YYYY-MM-DD");
                          const inputDate = moment(
                            inputDateString,
                            "YYYY-MM-DD"
                          );
                          const resultDate = inputDate.add(90, "days");
                          const resultDateString =
                            resultDate.format("YYYY-MM-DD");
                          setExpriyDate(resultDateString);
                        }}
                        minDate={minDate}
                        maxDate={
                          values.lastEntry
                            ? new Date(values.lastEntry)
                            : new Date()
                        }
                      />
                    )}
                  </Field>
                )}
                <ErrorMessage
                  name="lastExit"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="xl:ml-2 ml-0 xl:w-[42vh] 2xl:w-[60vh] w-full">
                <input
                  type="text"
                  name="lastExitDays"
                  value={lastEntryExitDays}
                  placeholder="Days"
                  className="border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px] focus:outline-none py-[12px]"
                />
              </div>
            </div>
            <div className="w-full flex justify-center mt-5">
              <Field name="lastEntry">
                {({ field, form }: FieldProps) => (
                  <Button
                    {...field}
                    type="button"
                    label="Reset all"
                    disabled={false}
                    handleClick={() => {
                      setLastEntryExitDays(0);
                      setLastEntryDays(0);
                      setExpriyDate("");
                      setLeftDays(0);
                      setDateRange(null);
                      form.setFieldValue("lastEntry", null);
                    }}
                    cssClass="bg-[#71859A] text-[#FFFFFF] py-[10px] px-[24px] w-fit rounded-lg text-center text-[16px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed mb-2"
                  />
                )}
              </Field>
            </div>
            <hr className="bg-[#E1E5EA] my-5" />
            <div className="flex lg:flex-row xl:flex-row 2xl:flex-row flex-col gap-2">
              <div className="days lg:w-1/2 xl:w-1/2 2xl:w-1/2 sm:w-full">
                <label
                  htmlFor={"Schengen-area"}
                  className="text-[#C3CCD5] text-[14px]"
                >
                  Day left on Schengen area{" "}
                  <span className="text-[#FF4444] ml-1">*</span>
                </label>
                <input
                  type="text"
                  value={leftDays}
                  className="border-[2px] border-[#E1E5EA] rounded-md w-full text-[#C3CCD5] placeholder:text-[14px] focus:outline-none"
                  disabled
                />
              </div>
              <div className="days lg:w-1/2 xl:w-1/2 2xl:w-1/2 sm:w-full">
                <label
                  htmlFor={"Schengen-area"}
                  className="text-[#C3CCD5] text-[14px]"
                >
                  Days period expiry date{" "}
                  <span className="text-[#FF4444] ml-1">*</span>
                </label>
                <input
                  type="text"
                  value={expirydate}
                  className="border-[2px] border-[#E1E5EA] rounded-md w-full text-[#C3CCD5] placeholder:text-[14px] focus:outline-none"
                  disabled
                />
              </div>
            </div>
            <hr className="bg-[#E1E5EA] my-5" />
            <div className="mb-2 mt-4 flex justify-between items-center">
              <label
                htmlFor={"passportFile"}
                className="font-400 text-[14px] text-[#0B0D0F]                    "
              >
                Upload ALL the pages of your passport proving your entries and
                exits from Schengen area
              </label>
            </div>
            <Field name="passportFile">
              {({ field, form }: FieldProps) => (
                <>
                  <Inputfile
                    {...field}
                    mainClass="lg:w-1/2 xl:w-1/2 w-full"
                    btnClass={null}
                    handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const selectedFile: FileList | null = event.target.files;
                      setfileError(null);
                      selectedFile && setFiles(Array.from(selectedFile));
                      selectedFile &&
                        form.setFieldValue(
                          "passportFile",
                          Array.from(selectedFile)
                        );
                      uploadFiles(event.target.files);
                    }}
                    multiple={true}
                  />
                  {values.passportFile &&
                    values.passportFile.length > 0 &&
                    values.passportFile.map(
                      (item: { name: string; location: string }) => (
                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 overflow-hidden">
                          <img src={documentBlack} alt="documentBlack" />
                          <label className="flex-1">{item.name}</label>
                          <img
                            src={crossBlack}
                            alt="documentBlack"
                            onClick={() => {
                              const updatedFiles = values.passportFile?.filter(
                                (val: { name: string; location: string }) =>
                                  val.name !== item.name
                              );
                              form.setFieldValue("passportFile", updatedFiles);
                              if (updatedFiles.length === 0) {
                                form.setFieldValue("passportFile", null);
                                setfileError("Files are required");
                              }
                            }}
                            className="cursor-pointer"
                          />
                        </div>
                      )
                    )}
                </>
              )}
            </Field>
            {/* {files &&
              files?.map((item) => (
                <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 overflow-hidden">
                  <img src={documentBlack} alt="documentBlack" />
                  <label className="flex-1">{item.name}</label>
                  <img
                    src={crossBlack}
                    alt="documentBlack"
                    onClick={() => removeFile(item.name)}
                    className="cursor-pointer"
                  />
                </div>
              ))} */}
            {fileError ? (
              <div className="text-red-500 text-sm md:text-base fileError">
                {fileError}
              </div>
            ) : (
              <ErrorMessage
                name="passportFile"
                component="div"
                className="text-red-600 fileError"
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepSix;

export const convertbas64Tofile = (data: string) => {
  console.log("🚀 ~ convertbas64Tofile ~ data:", data,typeof data)
  if (data ) {
    let arr: string[] = data.split(","),
      mime = (arr[0]?.match(/:(.*?);/) ?? [])[1] || "defaultMime",
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const signature = new File([u8arr], "signature", {
      type: mime,
    });
    return signature;
  }
};

export const validateFiles = (
  files: FileList | null | File[],
  allowedTypes: string[]
) => {
  const maxSizeMB = 5;
  const maxFiles = 20;
  const isValidType =
    files &&
    Array.from(files).every((file: File) => allowedTypes.includes(file.type));
  const isValidSize =
    files &&
    Array.from(files).every(
      (file: File) => file.size / 1024 / 1024 <= maxSizeMB
    );
  const isValidNumberOfFiles = files && files.length <= maxFiles;
  if (!isValidType) {
    return "Please upload only jpg,jpeg,pdf or png files";
  } else if (!isValidSize) {
    return "File size exceeds the maximum limit of 5 MB";
  } else if (!isValidNumberOfFiles) {
    return "Please upload maximum 20 files at a time";
  } else {
    return "true";
  }
};

export const cleanAndLogout = async () => {
  await localStorage.removeItem("token");
};

import React, { SetStateAction, Dispatch } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";

import {
  downArrow,
  logo,
  notification,
  profile,
  searchIcon,
  toggle,
} from "utils/Images";
import { useAppSelector } from "store/Hooks";
import { userSelector } from "store/slices/User";

type headerProps = {
  toggleSidebar: () => void;
  setModal: Dispatch<SetStateAction<boolean>>;
  modal : boolean
};

const Header = ({ toggleSidebar, setModal,modal }: headerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user } = useAppSelector(userSelector);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if(!modal){
      setAnchorEl(event.currentTarget);

    }
  };
  const handleClose = async () => {
    setAnchorEl(null);
  };

  return (
    <header className="p-4 bg-white sticky top-0 border-b-[1px] border-[#E1E5EA] w-full z-[999]">
      <div className="flex items-center gap-3">
        <button
          className="toggle-menu focus:outline-none text-gray-100"
          onClick={toggleSidebar}
        >
          <img src={toggle} className="w-6 h-6" alt="toggle" />
        </button>
        <Link to="/dashboard">
          <img
            src={logo}
            className="dashboard-logo max-w-[130px] sm:max-w-full"
            alt="dashboard"
          />
        </Link>

        <div className="hidden border-[2px] border-[#E1E5EA] rounded-md w-full lg:flex xl:flex 2xl:flex lg:max-w-md xl:max-w-xl 2xl:max-w-2xl h-12 placeholder-[#71859A] placeholder:text-[14px] focus:outline-none ml-10 items-center mt-2">
          <input
            type="text"
            placeholder="Search"
            className="password-input border-none w-full focus:outline-none"
          />
          <img
            src={searchIcon}
            alt="searchIcon"
            className="w-6 h-6 ml-auto mr-2"
          />
        </div>
        <div className="flex ml-auto mt-2 gap-2 md:gap-4">
          <div className="flex notification w-[45px] h-[45px] justify-center items-center sm:h-12 sm:w-12 border-[2px] rounded-lg border-[#E1E5EA] ">
            <img
              src={notification}
              alt="notification"
              className="px-3 max-w-11 md:max-w-full"
            />
          </div>
          <div className="sm:flex notification h-12 border-[2px] rounded-lg border-[#E1E5EA] items-center text-[#0B0D0F] cursor-pointer">
            {/* <img src={profile} alt="notification" className="px-3 " />
            <label className="text-sm md:text-base">Robert Fox</label>
            <img src={downArrow} alt="arrow" className="px-3 " /> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                className="hidden md:inline-flex"
              >
                <img src={profile} alt="notification" className="px-3 " />
                <label className="text-sm md:text-base cursor-pointer">
                  {user?.name}
                </label>
                <img src={downArrow} alt="arrow" className="px-3 " />
              </IconButton>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                className="inline-flex md:hidden "
              >
                <Avatar
                  sx={{ width: 32, height: 32 }}
                  className="text-base md:text-lg"
                >
                  {user?.name.charAt(0).toUpperCase()}
                </Avatar>
              </IconButton>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <div onClick={() => setModal(true)} className="flex items-center justify-center">
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </div>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

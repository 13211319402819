import React from "react";
import { useAppDispatch } from "store/Hooks";
import { formDocument } from "utils/Images";

type formStepType = {
  step: number;
  status: string;
  label: string;
  count?: number;
};
type payloadType = {
  step: number;
  status: string;
};
type desktopStepperTypes = {
  currentStep?: number;
  formStatus: formStepType[] | null;
  handleStep?: (paylod: payloadType) => any;
};

const DesktopStepper: React.FC<desktopStepperTypes> = ({
  currentStep,
  formStatus,
  handleStep,
}) => {
  const dispatch = useAppDispatch();
  const isCompleted = () => {
    if (currentStep) {
      const data: formStepType | undefined = formStatus?.find((item, index) =>
        currentStep - 1 === index && item.status === "completed" ? true : false
      );
      return data;
    }
  };

  const getLabel = (data: formStepType) => {
    if (data.status === "completed") {
      return "text-[#E39D6B]";
    }
    if (data.status === "Inprogress") {
      return "text-[#0A4A5E]";
    }
    if (data.status === "pending") {
      if (data.step === 0) {
        return "text-[#0A4A5E]";
      } else {
        return "text-[#71859A]";
      }
    }
    if (data.status === "Skiped") {
      return "text-[#FF4444]";
    }
  };

  const getStep = (status: string, index: number) => {
    if (status === "completed") {
      const result = isCompleted();
      if (currentStep) {
        if (result && index === currentStep - 1) {
          return "text-[#E39D6B] bg-[#E39D6B] border-[3px] border-[#E39D6B] rounded-full px-1 bg-opacity-50";
        } else {
          return "text-[#FFFFFF] bg-[#E39D6B] border-[3px] border-[#E39D6B] rounded-full px-1";
        }
      }
    }
    if (status === "Inprogress") {
      return "text-[#0A4A5E] bg-[#E8F8FD] border-[3px] border-[#E8F8FD] rounded-full px-1";
    }
    if (status === "pending") {
      return "text-[#71859A] bg-[#E1E5EA] border-[3px] border-[#E1E5EA] rounded-full px-1";
    }
    if (status === "Skiped") {
      return "text-[#FFFFFF] bg-[#FF4444] border-[3px] border-[#FF4444] rounded-full px-1";
    }
  };

  const getClass = (status: string) => {
    if (status === "completed") {
      return "border-l-[3px] border-dotted  border-[#E39D6B] h-5 my-2";
    }
    if (status === "Inprogress") {
      return "border-l-[3px] border-dotted  border-[#E8F8FD] h-5 my-2";
    }
    if (status === "pending") {
      return "border-l-[3px] border-dotted  border-[#E1E5EA] h-5 my-2";
    }
    if (status === "Skiped") {
      return "border-l-[3px] border-dotted  border-[#FF4444] h-5 my-2";
    }
  };

  return (
    <div className="w-fit h-fit border-[2px] border-[#C3CCD5] rounded-[8px] bg-white hidden lg:block p-2">
      {formStatus?.map((item: formStepType, index) => (
        <div
          className="flex flex-col text-[14px] items-center cursor-pointer"
          onClick={() => {
            if (handleStep) {
              dispatch(handleStep({ step: index + 1, status: "Inprogress" }));
            }
          }}
          key={index}
        >
          {["Start", "Submit"].includes(item.label) ? (
            <>
              <div
                className={`flex step-text items-center justify-center gap-5`}
              >
                <span className={getLabel(item)}>{item.label}</span>
                <div className="image">
                  {/* <img src={formDocument} alt="image" /> */}
                </div>
              </div>

              {item.label !== "Submit" && (
                <div
                  className={`flex step-text items-center justify-center gap-5`}
                >
                  <div className={getClass(item.status)} />
                  <div className="image">
                    {/* <img src={formDocument} alt="image" /> */}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div
                className={`flex step-text items-center justify-center ${
                  item.count ? "gap-5" : "gap-2"
                }`}
              >
                {/* <span className={getLabel(item)}>Step </span> */}
                <div className="flex relative">
                  <span className={getLabel(item)}>
                    Step
                    {item.count && (
                      <span className="count-label absolute top-[-2px] right-0 left-[1.6rem] bg-[#FF4444] border-[1px] rounded-md text-white h-5 w-5 flex items-center justify-center">
                        {item.count}
                      </span>
                    )}
                  </span>
                </div>

                <span className={getStep(item.status, index)}>
                  {item.label}
                </span>
              </div>
              <div
                className={`flex step-text items-center justify-center gap-5`}
              >
                <div className={getClass(item.status)} />
                <div className="image">
                  {![4,7,8].includes(item.step) && (
                    <img src={formDocument} alt="image" />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default DesktopStepper;

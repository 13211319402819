import React, { ChangeEvent, useEffect, useState } from "react";
import { RadioGroup } from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { stepFiveSchema } from "schema/Prestudy";
import { stepFiveTypes, stepProps } from "utils/types";
import { addressOptions } from "utils/data";
import { CountryDropdown } from "react-country-region-selector";
import { prestudySelector, setCitizenship } from "store/slices/Prestudy";
import { useAppDispatch, useAppSelector } from "store/Hooks";

const StepFive: React.FC<stepProps> = ({ submitStepdata }) => {
  const CountrySelect = CountryDropdown as any;
  const { currentStep, stepData } = useAppSelector(prestudySelector);
  const dispatch = useAppDispatch();
  const [stepFields, setStepFields] = useState<stepFiveTypes>({
    citizinShip: "",
    additionalCitizinShip: "",
    citizenship1: "",
    citizenship2: "",
    citizenship3: "",
  });

  const initialValues: stepFiveTypes = {
    citizinShip: stepFields.citizinShip !== '' ? stepFields.citizinShip : '',
    additionalCitizinShip: stepFields.additionalCitizinShip !== '' ? stepFields.additionalCitizinShip : '',
    citizenship1: stepFields.citizenship1 !== '' ? stepFields.citizenship1 : '',
    citizenship2: stepFields.citizenship2 !== '' ? stepFields.citizenship2 : '',
    citizenship3: stepFields.citizenship3 !== '' ? stepFields.citizenship3 : '',
  };
  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof stepFiveTypes;
      switch (name) {
        case "citizinShip":
        case "additionalCitizinShip":
        case "citizenship1":
        case "citizenship2":
        case "citizenship3":
          updatedFields[name] = item.value;
          break;
        default:
          break;
      }
    });
    setStepFields(updatedFields);
  }, [stepData]);

  const handleSubmit = async (values: stepFiveTypes) => {
    dispatch(setCitizenship(values.citizinShip));
    let payload = [
      {
        name: "citizinShip",
        label: "Citizenship",
        value: values.citizinShip,
        isShow: true,
      },
      {
        name: "additionalCitizinShip",
        label: "Additional citizenship?",
        value: values.additionalCitizinShip,
        isShow: true,
      },
      {
        name: "citizenship1",
        label: "citizenship1",
        value: values.citizenship1,
        isShow: true,
      },
      {
        name: "citizenship2",
        label: "citizenship2",
        value: values.citizenship2,
        isShow: true,
      },
      {
        name: "citizenship3",
        label: "citizenship3",
        value: values.citizenship3,
        isShow: true,
      },
    ];
    await submitStepdata(payload, null);
  };
  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full">
      <label className="text-[#0B0D0F] font-semibold text-[20px]">
        Citizenships
      </label>
      <p className="text-[#71859A] leading-[27.36px] text-[15px] mt-5 lg:w-1/2 xl:w-1/2 w-full">
        If you have more than one citizenship, write here the one you use for
        your visa.{" "}
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={stepFiveSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values }: any) => (
          <Form className="mx-auto" id={currentStep.toString()}>
            <>
              <div className={"block"}>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"citizinShip"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Citizenship <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <div className="lg:w-1/2 xl:w-1/2 w-full">
                  <Field name="city">
                    {({ field, form }: FieldProps) => (
                      <CountrySelect
                        {...field}
                        value={values.citizinShip}
                        onChange={(val: string) =>
                          form.setFieldValue("citizinShip", val)
                        }
                        className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                      />
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name={"citizinShip"}
                  component="div"
                  className="text-red-500"
                />
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={"block"}>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"additionalCitizinShip"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Additional citizenship?
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="additionalCitizinShip">
                  {({ field, form }: FieldProps) => (
                    <div className="flex gap-8">
                      <RadioGroup
                        {...field}
                        fieldName="additionalCitizinShip"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(
                            "additionalCitizinShip",
                            event.target.value
                          );
                        }}
                        className={"ml-1"}
                        checkedField={values.additionalCitizinShip}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="additionalCitizinShip"
                  component="div"
                  className="text-red-500"
                />
              </div>
              {values.additionalCitizinShip === "Yes" && (
                <>
                  <div className={"block"}>
                    <div className="mb-2 mt-4 flex justify-between items-center">
                      <label
                        htmlFor={"citizenship1"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                      >
                        Additional citizenship1{" "}
                      </label>
                    </div>
                    <div className="lg:w-1/2 xl:w-1/2 w-full">
                      <Field name="citizenship1">
                        {({ field, form }: FieldProps) => (
                          <CountrySelect
                            {...field}
                            value={values.citizenship1}
                            onChange={(val: string) =>
                              form.setFieldValue("citizenship1", val)
                            }
                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={"block"}>
                    <div className="mb-2 mt-4 flex justify-between items-center">
                      <label
                        htmlFor={"citizenship2"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                      >
                        Additional citizenship2{" "}
                      </label>
                    </div>
                    <div className="lg:w-1/2 xl:w-1/2 w-full">
                      <Field name="citizenship2">
                        {({ field, form }: FieldProps) => (
                          <CountrySelect
                            {...field}
                            value={values.citizenship2}
                            onChange={(val: string) =>
                              form.setFieldValue("citizenship2", val)
                            }
                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={"block"}>
                    <div className="mb-2 mt-4 flex justify-between items-center">
                      <label
                        htmlFor={"citizenship3"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                      >
                        Additional citizenship3{" "}
                      </label>
                    </div>
                    <div className="lg:w-1/2 xl:w-1/2 w-full">
                      <Field name="citizenship3">
                        {({ field, form }: FieldProps) => (
                          <CountrySelect
                            {...field}
                            value={values.citizenship3}
                            onChange={(val: string) =>
                              form.setFieldValue("citizenship3", val)
                            }
                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                          />
                        )}
                      </Field>
                    </div>
                    <ErrorMessage
                      name={"citizenship1"}
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </>
              )}
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepFive;

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../Store";

type formStepType = {
  step: number;
  status: string;
  label: string;
};

export type initialStateType = {
  currentStep: number;
  formStatus: formStepType[] | null;
};

const initialState: initialStateType = {
  currentStep: 1,
  formStatus: Array(9)
    .fill(0)
    .map((_, index) => ({
      step: index,
      status: "pending",
      label: index === 0 ? "Start" : index === 8 ? "Submit" : `0${index}`,
    })),
};

export const formCorrectionSlice = createSlice({
  name: "formCorrect",
  initialState,
  reducers: {
    nextStep: (state) => {
      state.currentStep += 1;
    },
    previousStep: (state) => {
      state.currentStep -= 1;
    },
    updateStatus: (state, action) => {
      const { step, status } = action.payload;
      state.formStatus = state.formStatus && state.formStatus.map((item, index) =>
        index === step - 1
          ? ["pending", "Inprogress"].includes(status) &&
            item.status === "completed"
            ? item
            : { ...item, status }
          : item
      );
    },
    setStep: (state, action) => {
      const { step, status } = action.payload;
      state.currentStep = step;
      state.formStatus =
        state.formStatus &&
        state.formStatus.map((item, index) =>
          index === step - 1
            ? ["pending", "Inprogress"].includes(status) &&
              item.status === "completed"
              ? item
              : { ...item, status }
            : item.status === "Inprogress"
            ? { ...item, status: "pending" }
            : item
        );
    },
    clearFormcorrection: (state) => {
      state.currentStep = 0;
      state.formStatus = null;
    },
  },
});

export const { nextStep, previousStep, setStep, updateStatus,clearFormcorrection } =
  formCorrectionSlice.actions;
export const formCorrectionSelector = (state: RootState) =>
  state.formCorrectionReducer;
export default formCorrectionSlice.reducer;

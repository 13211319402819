import Api from "./Api";
import { forgetPwdPayloadType, loginPayloadType,resetPwdPayloadType } from "./types";
class AuthService {
  public async register(payload : FormData,step:number) {
    return await Api.post(`/auth/register?step=${step}`, payload);
  }
  public async login(payload : loginPayloadType) {
    return await Api.post("/auth/login", payload);
  }
  public async forgetPassword(payload : forgetPwdPayloadType) {
    return await Api.post("/auth/forgot-password", payload);
  }
  public async resetPassword(payload : resetPwdPayloadType,token:string|null) {
    return await Api.post(`/auth/reset-password?token=${token}`, payload);
  }
}

const authService = new AuthService();
export default authService;
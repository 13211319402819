import BarChartComponent from "components/BarChart";
import Layout from "components/UI/Layout";
import React, { useState } from "react";
import { chart, downArrow, toggle } from "utils/Images";
import { useAppSelector } from "store/Hooks";
import { userSelector } from "store/slices/User";
import * as menu from "@radix-ui/react-context-menu";
import { VEDcontextMenu, readyMenuItems, reminderMenuItems } from "utils/data";
import ContextMenuList from "components/ContextMenuList";
import BarChartModal from "components/Modals/BarChartModal";

const LawyerDashboard: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { sidebarOpen } = useAppSelector(userSelector);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // const data = [
  //   { name: "Jan", value: 1 },
  //   { name: "Feb", value: 3 },
  //   { name: "Mar", value: 4 },
  //   { name: "Apr", value: 2 },
  //   { name: "May", value: 5 },
  // ];
  return (
    <Layout>
      <div className="pb-16" onContextMenu={(e) => e.preventDefault()}>
        <h1 className="p-4 text-[22px] md:text-[24px] font-semibold">
          Dashboard Overview
        </h1>
        <div className="flex flex-col xl:flex-row justify-between gap-2 md:gap-4 p-4">
          <div className="w-full md:w-full  xl:w-1/3 flex flex-col ">
            <h1 className="text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
              New
            </h1>
            <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[250px] md:max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
              <table className="min-w-full ">
                <thead className="bg-[#C3CCD5] sticky top-0">
                  <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                    <td className="py-3 px-3 w-1/3">Student ID</td>
                    <td className="py-3 px-3 w-1/3">VED</td>
                    <td className="py-3 px-3 w-1/3">Process</td>
                    <td className="py-3 px-3 w-1/3">City</td>
                  </tr>
                </thead>
                <tbody className="px-3 bg-white">
                  <tr
                    className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                  >
                    <td className="px-3 py-3 w-1/3">Q1231231</td>
                    <td className="px-3 py-3 w-1/3">2024-12-01</td>
                    <td className="px-3 py-3 w-1/3">Study</td>
                    <td className="px-3 py-3 w-1/3">Berlin</td>
                  </tr>
                  <tr
                    className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                  >
                    <td className="px-3 py-3 w-1/3">Q1231231</td>
                    <td className="px-3 py-3 w-1/3">2024-12-01</td>
                    <td className="px-3 py-3 w-1/3">Study</td>
                    <td className="px-3 py-3 w-1/3">Berlin</td>
                  </tr>
                  <tr
                    className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                  >
                    <td className="px-3 py-3 w-1/3">Q1231231</td>
                    <td className="px-3 py-3 w-1/3">2024-12-01</td>
                    <td className="px-3 py-3 w-1/3">Study</td>
                    <td className="px-3 py-3 w-1/3">Berlin</td>
                  </tr>

                  <tr
                    className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                  >
                    <td className="px-3 py-3 w-1/3">Q1231231</td>
                    <td className="px-3 py-3 w-1/3">2024-12-01</td>
                    <td className="px-3 py-3 w-1/3">Post Study</td>
                    <td className="px-3 py-3 w-1/3">Berlin</td>
                  </tr>
                  <tr
                    className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                  >
                    <td className="px-3 py-3 w-1/3">Q1231231</td>
                    <td className="px-3 py-3 w-1/3">2024-12-01</td>
                    <td className="px-3 py-3 w-1/3">Study</td>
                    <td className="px-3 py-3 w-1/3">Potsdam</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full md:w-full xl:w-1/3 flex flex-col ">
            <h1 className="text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
              VED to check
            </h1>
            <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[250px] md:max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
              <table className="min-w-full  ">
                <thead className="bg-[#C3CCD5] sticky top-0">
                  <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                    <td className="py-3 px-3 w-1/4">Student ID</td>
                    <td className="py-3 px-3 w-1/4">VED</td>
                    <td className="py-3 px-3 w-1/4">Process</td>
                    <td className="py-3 px-3 w-1/4">City</td>
                  </tr>
                </thead>
                <tbody className="px-3 bg-white">
                  <menu.Root>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5] cursor-pointer
                  }`}
                    >
                      <menu.Trigger className="contents">
                        <td className="px-3 py-3 w-1/4">Q1231231</td>
                        <td className="px-3 py-3 w-1/4">2024-12-01</td>
                        <td className="px-3 py-3 w-1/4">Study</td>
                        <td className="px-3 py-3 w-1/4">Berlin</td>
                      </menu.Trigger>
                    </tr>
                    <ContextMenuList
                      menuItems={VEDcontextMenu}
                      studentId={"Q1231234"}
                    />
                  </menu.Root>
                  <tr
                    className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                  >
                    <td className="px-3 py-3 w-1/4">Q1231231</td>
                    <td className="px-3 py-3 w-1/4">2024-12-01</td>
                    <td className="px-3 py-3 w-1/4">Post Study</td>
                    <td className="px-3 py-3 w-1/4">Berlin</td>
                  </tr>
                  <tr
                    className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                  >
                    <td className="px-3 py-3 w-1/4">Q1231231</td>
                    <td className="px-3 py-3 w-1/4">2024-12-01</td>
                    <td className="px-3 py-3 w-1/4">Study</td>
                    <td className="px-3 py-3 w-1/4">Potsdam</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full md:w-full  xl:w-1/3 flex flex-col ">
            <h1 className=" text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
              Ready to check
            </h1>
            <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[250px] md:max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
              <table className="min-w-full ">
                <thead className="bg-[#C3CCD5] sticky top-0">
                  <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                    <td className="py-3 px-3 w-1/4">Student ID</td>
                    <td className="py-3 px-3 w-1/4">VED</td>
                    <td className="py-3 px-3 w-1/4">Process</td>
                    <td className="py-3 px-3 w-1/4">City</td>
                  </tr>
                </thead>
                <tbody className="px-3 bg-white">
                  <menu.Root>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5] cursor-pointer
                  }`}
                    >
                      <menu.Trigger className="contents">
                        <td className="px-3 py-3 w-1/4">Q1231231</td>
                        <td className="px-3 py-3 w-1/4">2024-12-01</td>
                        <td className="px-3 py-3 w-1/4">Study</td>
                        <td className="px-3 py-3 w-1/4">Berlin</td>
                      </menu.Trigger>
                    </tr>
                    <ContextMenuList
                      menuItems={readyMenuItems}
                      studentId={"Q1231234"}
                    />
                  </menu.Root>
                  <tr
                    className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                  >
                    <td className="px-3 py-3 w-1/4">Q1231231</td>
                    <td className="px-3 py-3 w-1/4">2024-12-01</td>
                    <td className="px-3 py-3 w-1/4">Post Study</td>
                    <td className="px-3 py-3 w-1/4">Berlin</td>
                  </tr>
                  <tr
                    className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                  >
                    <td className="px-3 py-3 w-1/4">Q1231231</td>
                    <td className="px-3 py-3 w-1/4">2024-12-01</td>
                    <td className="px-3 py-3 w-1/4">Study</td>
                    <td className="px-3 py-3 w-1/4">Potsdam</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex flex-col xl:flex-row justify-between gap-2 md:gap-4 p-4">
          <div className="flex flex-col gap-2 md:gap-4 flex-[2]">
            <div className="w-full md:w-full flex flex-col flex-1">
              <h1 className="text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
                Reminders for today
              </h1>
              <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[250px] md:max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
                <table className="min-w-full">
                  <thead className="bg-[#C3CCD5] sticky top-0">
                    <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                      <td className="py-3 px-3 w-1/5">Student ID</td>
                      <td className="py-3 px-3 w-1/5">VED</td>
                      <td className="py-3 px-3 w-1/5">Status</td>
                      <td className="py-3 px-3 w-1/5">Reason</td>
                      <td className="py-3 px-3 w-1/5">Name</td>
                    </tr>
                  </thead>
                  <tbody className="px-3 bg-white">
                    <menu.Root>
                      <tr
                        className={`px-3 text-[#0B0D0F] cursor-pointer
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                      >
                        <menu.Trigger className="contents">
                          <td className="px-3 py-3 w-1/5">Q1231231</td>
                          <td className="px-3 py-3 w-1/5">2024-12-01</td>
                          <td className="px-3 py-3 w-1/5">
                            Waiting for correction
                          </td>
                          <td className="px-3 py-3 w-1/5">Blocked account</td>
                          <td className="px-3 py-3 w-1/5">Asd</td>
                        </menu.Trigger>
                      </tr>
                      <ContextMenuList
                        menuItems={reminderMenuItems}
                        studentId={"Q1231234"}
                      />
                    </menu.Root>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/5">Q1231231</td>
                      <td className="px-3 py-3 w-1/5">2024-12-01</td>
                      <td className="px-3 py-3 w-1/5">Open</td>
                      <td className="px-3 py-3 w-1/5"></td>
                      <td className="px-3 py-3 w-1/5">Yxc</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/5">Q1231231</td>
                      <td className="px-3 py-3 w-1/5">2024-12-01</td>
                      <td className="px-3 py-3 w-1/5">Ready</td>
                      <td className="px-3 py-3 w-1/5">Financial letter</td>
                      <td className="px-3 py-3 w-1/5">Qwe</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/5">Q1231231</td>
                      <td className="px-3 py-3 w-1/5">2024-12-01</td>
                      <td className="px-3 py-3 w-1/5">Ready</td>
                      <td className="px-3 py-3 w-1/5">Financial letter</td>
                      <td className="px-3 py-3 w-1/5">Qwe</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/5">Q1231231</td>
                      <td className="px-3 py-3 w-1/5">2024-12-01</td>
                      <td className="px-3 py-3 w-1/5">Ready</td>
                      <td className="px-3 py-3 w-1/5">Financial letter</td>
                      <td className="px-3 py-3 w-1/5">Qwe</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="w-full md:w-full flex flex-col flex-1">
              <h1 className="text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
                File Ready for LEAv
              </h1>
              <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[250px] md:max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
                <table className="min-w-full">
                  <thead className="bg-[#C3CCD5] sticky top-0">
                    <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                      <td className="py-3 px-3 w-1/5">Student ID</td>
                      <td className="py-3 px-3 w-1/5">VED</td>
                      <td className="py-3 px-3 w-1/5">Status</td>
                      <td className="py-3 px-3 w-1/5">Reason</td>
                      <td className="py-3 px-3 w-1/5">Name</td>
                    </tr>
                  </thead>
                  <tbody className="px-3 bg-white">
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/5">Q1231231</td>
                      <td className="px-3 py-3 w-1/5">2024-12-01</td>
                      <td className="px-3 py-3 w-1/5">
                        Waiting for correction
                      </td>
                      <td className="px-3 py-3 w-1/5">Blocked account</td>
                      <td className="px-3 py-3 w-1/5">Asd</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/5">Q1231231</td>
                      <td className="px-3 py-3 w-1/5">2024-12-01</td>
                      <td className="px-3 py-3 w-1/5">Open</td>
                      <td className="px-3 py-3 w-1/5"></td>
                      <td className="px-3 py-3 w-1/5">Yxc</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/5">Q1231231</td>
                      <td className="px-3 py-3 w-1/5">2024-12-01</td>
                      <td className="px-3 py-3 w-1/5">Ready</td>
                      <td className="px-3 py-3 w-1/5">Financial letter</td>
                      <td className="px-3 py-3 w-1/5">Qwe</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1">
            <div className="w-full flex flex-col flex-1">
              <h1 className="text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
                Services
              </h1>
              <div className=" min-w-full text-[15px] flex flex-1 max-h-[330px] md:max-h-[480px] overflow-y-scroll no-scrollbar border-[1px] rounded-[8px]">
                <table className="min-w-full  overflow-hidden  ">
                  <tbody className="px-3 bg-white">
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">10</td>
                      <td className="px-3 py-3 w-1/3">Open</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">20</td>
                      <td className="px-3 py-3 w-1/3">Ready to check</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">100</td>
                      <td className="px-3 py-3 w-1/3">
                        Waiting for correction
                      </td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">150</td>
                      <td className="px-3 py-3 w-1/3">Correction ready</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">100</td>
                      <td className="px-3 py-3 w-1/3">Ready for LEA</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">20</td>
                      <td className="px-3 py-3 w-1/3">At LEA</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">10</td>
                      <td className="px-3 py-3 w-1/3">Invited</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">10</td>
                      <td className="px-3 py-3 w-1/3">Ready for invoice</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">10</td>
                      <td className="px-3 py-3 w-1/3">invoiced</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">10</td>
                      <td className="px-3 py-3 w-1/3">out</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">10</td>
                      <td className="px-3 py-3 w-1/3">out</td>
                    </tr>
                    <tr
                      className={`px-3 text-[#0B0D0F]
                  bg-white border-b-[2px] border-[#C3CCD5]
                  }`}
                    >
                      <td className="px-3 py-3 w-1/3">10</td>
                      <td className="px-3 py-3 w-1/3">out</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          // <div className="fixed inset-0 z-50 flex lg:items-end 2xl:items-end xl:items-end justify-end px-4 py-10">
          //   <div
          //     className="fixed inset-0 bg-[#000] bg-opacity-60"
          //     onClick={toggleModal}
          //   ></div>
          //   <div className="relative z-10 w-full max-w-xl rounded-lg mt-auto overflow-y-auto">
          //     <div className="overflow-y-auto max-h-[80vh]">
          //       {/* Add max height for scrolling on smaller screens */}
          //       <div className="w-full flex flex-col">
          //         <div className="bg-white border-b-[0px] rounded-t-[12px] p-[12px] md:p-[24px] overflow-hidden">
          //           <div className="flex items-center justify-between">
          //             <h1 className="text-[#0B0D0F] text-[16px] md:text-[24px] font-semibold">
          //               Statistic
          //             </h1>
          //             <div className="flex notification border-[2px] rounded-lg border-[#E1E5EA] items-center text-[#0B0D0F] gap-4 py-2 px-4">
          //               <label className="font-semibold text-[13px] md:text-[15px] text-[#71859A]">
          //                 2024
          //               </label>
          //               <img src={downArrow} alt="arrow" className="" />
          //             </div>
          //           </div>
          //         </div>
          //         <div className="bg-white p-[5px] md:p-[24px] grid grid-cols-2 md:grid-cols-4 border-[2px] border-t-[#C3CCD5] border-b-0">
          //           <div className="bg-white border-[#C3CCD5] rounded-[12px] p-3 flex flex-col flex-1 justify-between gap-2 md:gap-[15px]">
          //             <p className="text-[13px] md:text-[14px] text-[#71859A] font-semibold">
          //               New Study
          //             </p>
          //             <p className="text-[18px] text-[#48C928] font-semibold">
          //               10 (+50%)
          //             </p>
          //           </div>
          //           <div className="bg-white border-[#C3CCD5] rounded-[12px] p-3 flex flex-col flex-1 justify-between gap-2">
          //             <p className="text-[13px] md:text-[14px] text-[#71859A] font-semibold">
          //               New Post-Study
          //             </p>
          //             <p className="text-[18px] text-[#48C928] font-semibold">
          //               5 (-25%)
          //             </p>
          //           </div>
          //           <div className="bg-white border-[#C3CCD5] rounded-[12px] p-3 flex flex-col flex-1 justify-between gap-2">
          //             <p className="text-[13px] md:text-[14px] text-[#71859A] font-semibold">
          //               Process finished
          //             </p>
          //             <p className="text-[18px] text-[#48C928] font-semibold">
          //               12 (+20%)
          //             </p>
          //           </div>
          //           <div className="bg-white border-[#C3CCD5] rounded-[12px] p-3 flex flex-col flex-1 justify-between gap-2">
          //             <p className="text-[13px] md:text-[14px] text-[#71859A] font-semibold">
          //               Invoiced
          //             </p>
          //             <p className="text-[18px] text-[#48C928] font-semibold">
          //               12 (+20%)
          //             </p>
          //           </div>
          //         </div>
          //         <div className="bg-white  rounded-b-[12px]">
          //           <BarChartComponent data={data} />
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>
          <BarChartModal toggleModal={toggleModal} />
        )}
        <footer className="bg-white p-4 text-[#71859A] fixed right-0 bottom-0 w-full">
          <div className="w-full flex items-center justify-between gap-1">
            <span
              className={`text-[15px] ${sidebarOpen ? "lg:ml-60" : "lg:ml-16"}`}
            >
              @2024 SIAP. All rights reserved.
            </span>
            <div className="flex justify-between gap-2">
              <img
                src={toggle}
                alt="toggle-icon"
                className="w-6 cursor-pointer"
                onClick={() => {}}
              />
              <img
                src={chart}
                alt="chart-icon"
                className="w-6 cursor-pointer"
                onClick={toggleModal}
              />
            </div>
          </div>
        </footer>
      </div>
    </Layout>
  );
};

export default LawyerDashboard;

import React from "react";
import BarChartComponent from "components/BarChart";
import { downArrow } from "utils/Images";

interface BarChartModalProps {
  toggleModal: () => void;
}

const BarChartModal = ({ toggleModal }: BarChartModalProps) => {
  const data = [
    { name: "Jan", value: 1 },
    { name: "Feb", value: 3 },
    { name: "Mar", value: 4 },
    { name: "Apr", value: 2 },
    { name: "May", value: 5 },
  ];
  return (
    <>
      <div className="fixed inset-0 z-[99999999] flex lg:items-end 2xl:items-end xl:items-end justify-end px-4 py-10">
        <div
          className="fixed inset-0 bg-[#000] bg-opacity-60"
          onClick={toggleModal}
        ></div>
        <div className="relative z-10 w-full max-w-xl rounded-lg mt-auto overflow-y-auto">
          <div className="overflow-y-auto max-h-[80vh]">
            {/* Add max height for scrolling on smaller screens */}
            <div className="w-full flex flex-col">
              <div className="bg-white border-b-[0px] rounded-t-[12px] p-[12px] md:p-[24px] overflow-hidden">
                <div className="flex items-center justify-between">
                  <h1 className="text-[#0B0D0F] text-[16px] md:text-[24px] font-semibold">
                    Statistic
                  </h1>
                  <div className="flex notification border-[2px] rounded-lg border-[#E1E5EA] items-center text-[#0B0D0F] gap-4 py-2 px-4">
                    <label className="font-semibold text-[13px] md:text-[15px] text-[#71859A]">
                      2024
                    </label>
                    <img src={downArrow} alt="arrow" className="" />
                  </div>
                </div>
              </div>
              <div className="bg-white p-[5px] md:p-[24px] grid grid-cols-2 md:grid-cols-4 border-[2px] border-t-[#C3CCD5] border-b-0">
                <div className="bg-white border-[#C3CCD5] rounded-[12px] p-3 flex flex-col flex-1 justify-between gap-2 md:gap-[15px]">
                  <p className="text-[13px] md:text-[14px] text-[#71859A] font-semibold">
                    New Study
                  </p>
                  <p className="text-[18px] text-[#48C928] font-semibold">
                    10 (+50%)
                  </p>
                </div>
                <div className="bg-white border-[#C3CCD5] rounded-[12px] p-3 flex flex-col flex-1 justify-between gap-2">
                  <p className="text-[13px] md:text-[14px] text-[#71859A] font-semibold">
                    New Post-Study
                  </p>
                  <p className="text-[18px] text-[#48C928] font-semibold">
                    5 (-25%)
                  </p>
                </div>
                <div className="bg-white border-[#C3CCD5] rounded-[12px] p-3 flex flex-col flex-1 justify-between gap-2">
                  <p className="text-[13px] md:text-[14px] text-[#71859A] font-semibold">
                    Process finished
                  </p>
                  <p className="text-[18px] text-[#48C928] font-semibold">
                    12 (+20%)
                  </p>
                </div>
                <div className="bg-white border-[#C3CCD5] rounded-[12px] p-3 flex flex-col flex-1 justify-between gap-2">
                  <p className="text-[13px] md:text-[14px] text-[#71859A] font-semibold">
                    Invoiced
                  </p>
                  <p className="text-[18px] text-[#48C928] font-semibold">
                    12 (+20%)
                  </p>
                </div>
              </div>
              <div className="bg-white  rounded-b-[12px]">
                <BarChartComponent data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarChartModal;

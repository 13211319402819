import { buttonPropTypes } from "utils/types";

const Button: React.FC<buttonPropTypes> = ({
  children,
  type,
  label,
  disabled,
  cssClass,
  handleClick,
  form,
  img,
  align
}) => {
  return (
    <button type={type} disabled={disabled} className={`${img ? `flex items-center justify-center ${cssClass}` : cssClass}`} onClick={handleClick} form={form}>
      {(img && align === 'before') && <img src={img} alt="img" className="mr-2"/>}
      {label}
      {(img && align === 'after') && <img src={img} alt="img" className="ml-2"/>}
      {children && children}
    </button>
  );
};

export default Button;

import axios, { AxiosResponse, AxiosError } from "axios";
import { toast } from "react-toastify";
import { cleanAndLogout } from "utils/Helper";

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

function handleError(err: AxiosError | any) {
  console.log("🚀 ~ handleError ~ err:", err.response);
  if (err.response && err.response.status === 401) {
    cleanAndLogout();
  }
  if (!err.response?.data?.success) {
    if (err.response?.data?.message) {
      toast.error(err.response?.data?.message);
      return;
    }
  }
  return err;
}

Api.interceptors.request.use(
  (config) => {
    let token;
    if (typeof window !== "undefined") {
      token = localStorage.getItem("token") || "";
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    handleError(error);
    return error.response;
  }
);

export default Api;

import { ChangeEvent, useRef, useState } from "react";
import { validateFiles } from "utils/Helper";
import { crossBlack, documentBlack } from "utils/Images";
import { InputfileProps } from "utils/types";

const Inputfile: React.FC<InputfileProps> = ({
  mainClass,
  btnClass,
  file,
  setFile,
  fileError,
  setfileError,
  handleChange,
  multiple,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = e.target.files;
    const allowedTypes = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
    const validate = await validateFiles(files, allowedTypes);
    if (validate === "true") {
      if (files?.length === 1) {
        setFile && setFile(files[0]);
      } else {
        setFile && setFile(files);
      }
      setfileError && setfileError(null);
      setError(null);
    } else {
      setfileError && setfileError(null);
      setError(validate);
    }
  };

  return (
    <>
      <div
        className={`border-dashed border-[2px] border-[#E1E5EA] rounded-md placeholder-[#71859A] placeholder:text-[14px] focus:outline-none h-[145px] bg-[#f2f4f6] flex flex-col items-center justify-center gap-2 password-input ${
          mainClass && mainClass
        }`}
      >
        <span className={"text-[#71859A]"}>No file chosen</span>
        <div
          id="uploadBtn"
          onClick={() => fileInputRef?.current?.click()}
          className={`border-[2px] border-[#99a8b8] rounded-[12px] px-4 py-2 bg-[#e1e7ed] text-[#556577] cursor-pointer ${
            btnClass && btnClass
          }`}
        >
          Choose File
        </div>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept=".jpg,.jpeg,.png,.pdf"
          onChange={handleChange ? handleChange : handleFileChange}
          multiple={multiple}
        />
      </div>
      {fileError && (
        <div className="text-red-500 text-sm md:text-base">{fileError}</div>
      )}
      {error && (
        <div className="text-red-500 text-sm md:text-base">{error}</div>
      )}
      {file && (
        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 overflow-hidden">
          <img src={documentBlack} alt="documentBlack" />
          <label className="flex-1">{file.name}</label>
          <img
            src={crossBlack}
            alt="documentBlack"
            onClick={() => setFile && setFile(null)}
            className="cursor-pointer"
          />
        </div>
      )}
    </>
  );
};

export default Inputfile;

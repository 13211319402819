import React from "react";

const CustomLoader: React.FC = () => {
  return (
    <div className="loader-container fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-opacity-50">
      <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
        <div className="spinner h-[40px] w-[40px] md:h-[50px] md:w-[50px] border-solid border-[4px] border-[#006497] rounded-full border-b-transparent border-r-transparent will-change-transform animate-spin" />
      </div>
    </div>
  );
};

export default CustomLoader;

import React from "react";
import Select from "react-select";

interface Props {
  isMulti?: boolean;
  optionsList?: any;
  handleChange?: (args: any) => void;
}

const options = [
  { value: "Berlin", label: "Berlin" },
  { value: "Study", label: "Study" },
];

const CustomOption = ({ innerProps, innerRef, data }: any) => (
  <div ref={innerRef} {...innerProps}>
    <div className="select-option">
      <input type="checkbox" checked={data.isSelected} className="cursor-pointer border-[2px] border-[#E1E5EA] w-5 h-5" />
      <span>{data.label}</span>
    </div>
  </div>
);
const MultiSelectInput = ({
  isMulti = true,
  optionsList = options,
  handleChange,
}: Props) => (
  <Select
    isMulti={isMulti}
    name="colors"
    options={optionsList}
    className="basic-multi-select rounded-[10px]"
    classNamePrefix="select"
    onChange={handleChange}
    components={{ Option: CustomOption }}
  />
);

export default MultiSelectInput;

import { calendarIcon } from "utils/Images";

type CustomdateInputProps = {
  value?: string;
  onClick?: () => void;
  className?: string;
  isCalendar: boolean;
};
const CustomDateInput: React.FC<CustomdateInputProps> = ({
  value,
  onClick,
  className,
  isCalendar,
}) => {
  return (
    <div className={className}>
      <div
        className="flex border-[2px] border-[#E1E5EA] rounded-[12px] py-1 max-w-full"
        onClick={onClick}
      >
        <input
          type={"text"}
          placeholder="YYYY-MM-DD"
          className="border-none password-input placeholder-[#71859A] placeholder:text-[14px] max-w-full"
          value={value}
        />
        {isCalendar && (
          <img
            src={calendarIcon}
            className="cursor-pointer ml-auto mr-7 calendar-img w-5 md:w-7"
            alt="calendarIcon"
          />
        )}
      </div>
    </div>
  );
};

export default CustomDateInput;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { registrationDataType, userType } from "services/types";

export type initialStateType = {
  user: userType | null;
  registerData: any | null;
  sidebarOpen: boolean;
  studentId : string | null;
  registrationData: registrationDataType[]| null;
};

const initialState: initialStateType = {
  user: null,
  registerData: null,
  sidebarOpen: window.innerWidth > 1024,
  studentId : null,
  registrationData : null
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setRegisterData: (state, action: PayloadAction<object | null>) => {
      state.registerData = { ...state.registerData, ...action.payload };
    },
    setSidebar: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload;
    },
    setStudentId: (state, action : PayloadAction<string | null>) => {
      state.studentId = action.payload
    },
    setRegistrationData:(state,action : PayloadAction<registrationDataType[] | null>) => {
      state.registrationData = action.payload
    },
    clearUser: (state) => {
      state.user = null;
      state.registerData = null;
      state.sidebarOpen = window.innerWidth > 1024;
    },
  },
});

export const { setUser, setRegisterData, setSidebar, setStudentId,clearUser,setRegistrationData } =
  userSlice.actions;
export const userSelector = (state: RootState) => state.userReducer;
export default userSlice.reducer;

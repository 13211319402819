import { ErrorMessage, Field } from "formik";
import React from "react";

type textareaTypes = {
  name: string;
  placeholder: string;
  rows: number;
  className: string;
};
export const Textarea: React.FC<textareaTypes> = ({
  name,
  placeholder,
  rows,
  className,
}) => {
  return (
    <>
      <Field
        className={`password-input border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px] ${
          className && className
        }`}
        as="textarea"
        id={name}
        name={name}
        placeholder={placeholder}
        rows={rows}
      />
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm md:text-base"
      />
    </>
  );
};

export default Textarea;

import { Formik, Form, FormikHelpers } from "formik";
import { loginFieldsTypes } from "utils/types";
import { loginSchema } from "schema/Auth";
import { Button, Input, Inputpassword } from "components/Form";
import { useAppDispatch } from "store/Hooks";
import { setUser } from "store/slices/User";
import { logo } from "utils/Images";
import authService from "services/AuthService";
import { toast } from "react-toastify";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const initialValues: loginFieldsTypes = {
    email: "",
    password: "",
  };

  const handleSubmit = async (
    values: loginFieldsTypes,
    { setSubmitting, resetForm }: FormikHelpers<loginFieldsTypes>
  ) => {
    try {
      setSubmitting(true);
      const response = await authService.login(values);
      const { data, message, success } = response.data;
      if (success) {
        toast(message, { className: "toast-success" });
        dispatch(
          setUser({
            ...data.user,
            ...data.token,
            isAuthenticated: true,
          })
        );
        await localStorage.setItem("token", data.token.access.token);
        setSubmitting(false);
        resetForm();
      }
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-[#E1E5EA]">
      <div className="bg-white md:w-full md:max-w-lg border-[2px] border-[#C3CCD5] rounded-lg md:mx-[1rem] sm:mx-0 mx-[1rem] my-3">
        <img
          src={logo}
          className="dashboard-logo mb-2 pt-[30px] pl-[30px]"
          alt="dashboard"
        />
        <div className="px-8">
          <h1 className="font-bold text-[19px] md:text-[24px]">Login</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"email"}
                    className="font-400 text-[14px] text-gray-800"
                  >
                    Email
                  </label>
                </div>
                <Input
                  type="email"
                  fieldName="email"
                  placeholder="Enter your email"
                  className={null}
                  setValue={values.email}
                  handleChange={handleChange}
                />
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"Password"}
                    className="font-400 text-[14px] text-gray-800"
                  >
                    Password
                  </label>
                </div>
                <Inputpassword
                  type="password"
                  label="Password"
                  fieldName="password"
                  placeholder="Password"
                  showLink={true}
                  className={null}
                />
                <div className="w-full flex justify-start mb-10">
                  <Button
                    type="submit"
                    label={isSubmitting ? " " : "Login"}
                    disabled={isSubmitting}
                    cssClass="bg-[#0A4A5E] text-[#FFFFFF] h-12 w-40 rounded-lg text-center text-[16px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                  >
                    {isSubmitting && <div className="loader mx-auto" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;

import CustomLoader from "components/Loader/CustomLoader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import prestudyService from "services/prestudy";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { clearFormcorrection } from "store/slices/FormCorrection";
import {
  clearPrestudy,
  setFormData,
  setFormStatus,
} from "store/slices/Prestudy";
import { clearUser, setUser, userSelector } from "store/slices/User";
import { cleanAndLogout } from "utils/Helper";
import { editIcon } from "utils/Images";
import { formDataType, stepDataType } from "utils/types";

const StepEight: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAppSelector(userSelector);
  const [showData, setShowData] = useState<stepDataType[] | null>(null);

  const getFormData = async () => {
    try {
      setLoading(true);
      let response = await prestudyService.getFormData(user?._id);
      const {
        data: { success, status, data },
      } = response;
      if (success) {
        setLoading(false);
        if (data?.stepStatus) {
          dispatch(setFormStatus(data?.stepStatus));
        }
        if (data?.stepFields) {
          const result = data?.stepFields.flatMap(
            (obj: formDataType) => obj.data
          );
          setShowData(result.slice(0, 35));
          dispatch(setFormData(data?.stepFields));
        }
      }
      if (status === 401) {
        setLoading(false);
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {}
  };

  useEffect(() => {
    getFormData();
  }, []);

  const getValue = (
    value: string | boolean | number | { name: string; location: string }[],
    name: string
  ) => {
    let val;
    switch (typeof value) {
      case "string":
        val = !isNaN(Date.parse(value))
          ? name !== "zipcode"
            ? moment(value).format("YYYY-MM-DD")
            : value
          : value;
        break;
      case "number":
        val = value;
        break;
      case "boolean":
        val = value ? "Yes" : "No";
        break;
      case "object":
        val = "Uploaded";
        break;
      default:
        break;
    }
    return val;
  };

  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full">
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="max-w-[500px] overflow-x-scroll min-w-full">
          <table className="min-w-full rounded-lg overflow-hidden bg-[#E8F8FD] border-[1px]">
            <thead>
              <tr className="float-left">
                <th className="py-3 px-3 ">Review your inputs</th>
              </tr>
            </thead>
            <tbody className="px-3 bg-white">
              <tr className={`px-3 text-[#0B0D0F] text-[14px] bg-white`}>
                <td className="px-3 py-3 w-2/3">Username</td>
                <td className="px-3 py-3">
                  <span className="text-[#0B0D0F] font-semibold">
                    {user?.studentId}
                  </span>
                </td>
                <td className="pl-3 py-3">
                  <div className="w-full flex justify-end">
                    <img
                      src={editIcon}
                      alt="editIcon"
                      className="w-4 h-4 cursor-pointer max-w-fit mr-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className={`px-3 text-[#0B0D0F] text-[14px] bg-[#F0F2F4]`}>
                <td className="px-3 py-3 w-2/3">Your email</td>
                <td className="px-3 py-3">
                  <span className="text-[#0B0D0F] font-semibold">
                    {user?.email}
                  </span>
                </td>
                <td className="pl-3 py-3">
                  <div className="w-full flex justify-end">
                    <img
                      src={editIcon}
                      alt="editIcon"
                      className="w-4 h-4 cursor-pointer max-w-fit mr-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className={`px-3 text-[#0B0D0F] text-[14px] bg-white`}>
                <td className="px-3 py-3 w-2/3">University</td>
                <td className="px-3 py-3">
                  <span className="text-[#0B0D0F] font-semibold">
                    {user?.university}
                  </span>
                </td>
                <td className="pl-3 py-3">
                  <div className="w-full flex justify-end">
                    <img
                      src={editIcon}
                      alt="editIcon"
                      className="w-4 h-4 cursor-pointer max-w-fit mr-5"
                    />
                  </div>
                </td>
              </tr>
              <tr className={`px-3 text-[#0B0D0F] text-[14px] bg-[#F0F2F4]`}>
                <td className="px-3 py-3 w-2/3">consent_city</td>
                <td className="px-3 py-3">
                  <span className="text-[#0B0D0F] font-semibold">
                    {user?.city}
                  </span>
                </td>
                <td className="pl-3 py-3">
                  <div className="w-full flex justify-end">
                    <img
                      src={editIcon}
                      alt="editIcon"
                      className="w-4 h-4 cursor-pointer max-w-fit mr-5"
                    />
                  </div>
                </td>
              </tr>
              {showData &&
                showData?.map((item: any, index: number) => (
                  <>
                    <tr
                      className={`px-3 text-[#0B0D0F] text-[14px] ${
                        index % 2 === 0 ? "bg-white" : "bg-[#F0F2F4]"
                      }`}
                      key={index}
                    >
                      <td className="px-3 py-3 w-2/3">{item.label}</td>
                      <td className="px-3 py-3">
                        <span className="text-[#0B0D0F] font-semibold">
                          {getValue(item.value, item.name)}
                        </span>
                      </td>
                      <td className="pl-3 py-3">
                        <div className="w-full flex justify-end">
                          <img
                            src={editIcon}
                            alt="editIcon"
                            className="w-4 h-4 cursor-pointer max-w-fit mr-5"
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default StepEight;

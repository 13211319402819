import Layout from "components/UI/Layout";
import React, {
  useState,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import AgreementForm from "./AgreementForm";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import { Button } from "components/Form";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import {
  setStepData,
  nextStep,
  prestudySelector,
  previousStep,
  setStep,
  updateStatus,
  setFormStatus,
  setQrImage,
  clearPrestudy,
} from "store/slices/Prestudy";
import StepSeven from "./StepSeven";
import StepEight from "./StepEight";
import StepNine from "./StepNine";
import StepSix from "./StepSix";
import SubmitForm from "./SubmitForm";
import { clearUser, userSelector } from "store/slices/User";
import MobileStepper from "components/MobileStepper";
import DesktopStepper from "components/DesktopStepper";
import MandatoryFieldsModal from "components/Modals/MandatoryFieldsModal";
import StepTwo from "./StepTwo";
import Loader from "components/Loader";
import prestudyService from "services/prestudy";
import { cleanAndLogout } from "utils/Helper";
import { toast } from "react-toastify";
import { stepDataType } from "utils/types";
import { formStepType, saveStepDataType } from "services/types";
import { clearFormcorrection } from "store/slices/FormCorrection";

const Prestudy: React.FC = () => {
  const { sidebarOpen, user } = useAppSelector(userSelector);
  const { currentStep, formStatus, stepData } =
    useAppSelector(prestudySelector);

  const dispatch = useAppDispatch();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const handleNext = async () => {
    if ([8, 9].includes(currentStep)) {
      submitFormStep(setLoading, null);
    }
    if ([8, 9, 10].includes(currentStep)) {
      dispatch(updateStatus({ step: currentStep, status: "completed" }));
    } else {
      dispatch(updateStatus({ step: currentStep, status: "Skiped" }));
    }
    dispatch(updateStatus({ step: currentStep + 1, status: "Inprogress" }));
    dispatch(nextStep());
  };

  const handlePrevious = () => {
    dispatch(previousStep());
    dispatch(updateStatus({ step: currentStep, status: "pending" }));
    dispatch(updateStatus({ step: currentStep - 1, status: "Inprogress" }));
  };

  const getCurrentStepData = async (currentStep: number) => {
    try {
      setLoading(true);
      let response = await prestudyService.getStepdata(
        user?._id,
        currentStep - 1
      );
      const {
        data: { data, success, status },
      } = response;
      if (success) {
        dispatch(setStepData(data?.data));
        setLoading(false);
      }
      if (status === 401) {
        setLoading(false);
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const uploadDocument = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    formData: FormData | null
  ) => {
    try {
      if (!!formData) {
        setLoading(true);
        let response = await prestudyService.uploadDocument(formData);
        const {
          data: { success, status },
        } = response;
        if (success) {
          setLoading(false);
          return true;
        }
        if (status === 401) {
          setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
          return false;
        }
      } else {
        return true;
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const submitFormStep = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    payload: stepDataType[] | null
  ) => {
    try {
      if (formStatus) {
        let formStatusData: formStepType[] = formStatus.map((obj) => ({
          ...obj,
        }));
        if (formStatusData[currentStep - 1].hasOwnProperty("status")) {
          formStatusData[currentStep - 1].status = "completed";
          if (currentStep < formStatusData.length) {
            formStatusData[currentStep].status = "Inprogress";
          }
        }
        const stepDataPayload: saveStepDataType = {
          stepStatus: formStatusData,
          stepData: payload,
        };
        setLoading(true);
        let response = await prestudyService.saveStepData(
          user?._id,
          currentStep - 1,
          stepDataPayload
        );
        const {
          data: { data, success, status },
        } = response;
        if (success) {
          if (data?.imageUrl) {
            dispatch(setQrImage(data?.imageUrl));
          }
          setLoading(false);
          return true;
        }
        if (status === 401) {
          setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
          return false;
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const submitStepdata = async (
    payload: stepDataType[] | null,
    formData: FormData | null
  ) => {
    try {
      let isSubmitDocument, isSubmitData;
      setLoading(true);
      isSubmitData = await submitFormStep(setLoading, payload);
      if ([0, 1, 2, 3, 5].includes(currentStep - 1)) {
        isSubmitDocument = await uploadDocument(setLoading, formData);
        if (isSubmitDocument && isSubmitData) {
          dispatch(updateStatus({ step: currentStep, status: "completed" }));
          dispatch(
            updateStatus({ step: currentStep + 1, status: "Inprogress" })
          );
          dispatch(nextStep());
        }
      } else {
        if (isSubmitData) {
          dispatch(updateStatus({ step: currentStep, status: "completed" }));
          dispatch(
            updateStatus({ step: currentStep + 1, status: "Inprogress" })
          );
          dispatch(nextStep());
        }
      }
      toast("Form step details updated successfully!", {
        className: "toast-success",
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const getFormData = async () => {
    try {
      setLoading(true);
      let response = await prestudyService.getFormData(user?._id);
      const {
        data: { success, status, data },
      } = response;
      if (success) {
        setLoading(false);
        if (data?.stepStatus) {
          dispatch(setFormStatus(data?.stepStatus));
        }
      }
      if (status === 401) {
        setLoading(false);
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {}
  };

  useEffect(() => {
    getFormData();
  }, []);

  useEffect(() => {
    getCurrentStepData(currentStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const renderForm: React.ReactNode = useMemo(() => {
    switch (currentStep) {
      case 1:
        return <AgreementForm submitStepdata={submitStepdata} />;
      case 2:
        return <StepTwo submitStepdata={submitStepdata} />;
      case 3:
        return <StepThree submitStepdata={submitStepdata} />;
      case 4:
        return <StepFour submitStepdata={submitStepdata} />;
      case 5:
        return <StepFive submitStepdata={submitStepdata} />;
      case 6:
        return <StepSix submitStepdata={submitStepdata} />;
      case 7:
        return <StepSeven submitStepdata={submitStepdata} />;
      case 8:
        return <StepEight />;
      case 9:
        return <StepNine />;
      case 10:
        return <SubmitForm submitStepdata={submitStepdata} />;
      // case 11:
      //   return <SubmitForm />;
      default:
        return <AgreementForm submitStepdata={submitStepdata} />;
    }
  }, [currentStep]);

  return (
    <Layout>
      <MobileStepper
        isDropdownVisible={isDropdownVisible}
        currentStep={currentStep}
        toogleDropdown={() =>
          setDropdownVisible((isDropdownVisible) => !isDropdownVisible)
        }
        formStatus={formStatus}
        handleStep={setStep}
        userType={"student"}
      />

      <div
        className={`pre-study-form lg:mt-5 mt-12 overflow-x-hidden max-h-full pb-16`}
      >
        <h1 className="font-bold text-[24px] lg:mx-0 mx-4">Form 1</h1>
        <div className="flex mt-8 gap-3 mb-3 lg:mx-0 mx-4">
          <DesktopStepper
            currentStep={currentStep}
            formStatus={formStatus}
            handleStep={setStep}
          />

          {loading ? <Loader /> : renderForm}
        </div>
        {currentStep === 1 ? (
          <div className="flex justify-center  md:hidden my-4">
            <Button
              type="submit"
              label="Start"
              disabled={false}
              cssClass="bg-[#0A4A5E] text-[15px] text-[#FFFFFF] py-1 px-7 w-fit rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
              form={currentStep.toString()}
            />
          </div>
        ) : (
          <div className="flex justify-center md:hidden my-4">
            <Button
              type="submit"
              label="Previous"
              disabled={false}
              cssClass="bg-[#E39D6B] text-[15px] text-[#FFFFFF] py-1 px-7 w-fit rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
              handleClick={currentStep > 1 ? handlePrevious : () => {}}
            />
            <Button
              type="submit"
              label="Save"
              disabled={false}
              cssClass="bg-[#76B312] text-[15px] text-[#FFFFFF] py-1 px-7 w-fit rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed lg:mx-3 2xl:mx-3 xl:mx-3 mx-1"
              // handleClick={currentStep > 1 ? handlePrevious : () => {}}
              form={currentStep.toString()}
            />
            {currentStep !== 10 && (
              <Button
                type="submit"
                label="Next"
                disabled={false}
                cssClass="bg-[#0A4A5E] text-[15px] text-[#FFFFFF] py-1 px-7 w-fit rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                handleClick={
                  currentStep < 8 && !stepData
                    ? () => {
                        setModal(true);
                      }
                    : handleNext
                }
              />
            )}
          </div>
        )}
      </div>
      <footer className="bg-white p-4 text-[#71859A] fixed right-0 bottom-0 w-full">
        <div className="w-full flex">
          <span
            className={` text-[15px] ${sidebarOpen ? "lg:ml-60" : "lg:ml-16"}`}
          >
            @2024 SIAP. All rights reserved.
          </span>
          {currentStep === 1 ? (
            <div className="mx-auto hidden md:flex">
              <Button
                type="submit"
                label="Start"
                disabled={false}
                cssClass="bg-[#0A4A5E] text-[15px] text-[#FFFFFF] py-1 px-7 w-fit rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                form={currentStep.toString()}
              />
            </div>
          ) : (
            <div className="mx-auto hidden md:flex">
              <Button
                type="submit"
                label="Previous"
                disabled={false}
                cssClass="bg-[#E39D6B] text-[15px] text-[#FFFFFF] py-1 px-7 w-fit rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                handleClick={currentStep > 1 ? handlePrevious : () => {}}
              />
              <Button
                type="submit"
                label="Save"
                disabled={false}
                cssClass="bg-[#76B312] text-[15px] text-[#FFFFFF] py-1 px-7 w-fit rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed lg:mx-3 2xl:mx-3 xl:mx-3 mx-1"
                // handleClick={currentStep > 1 ? handlePrevious : () => {}}
                form={currentStep.toString()}
              />
              {currentStep !== 10 && (
                <Button
                  type="submit"
                  label="Next"
                  disabled={false}
                  cssClass="bg-[#0A4A5E] text-[15px] text-[#FFFFFF] py-1 px-7 w-fit rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                  handleClick={
                    currentStep < 8 && !stepData
                      ? () => {
                          setModal(true);
                        }
                      : handleNext
                  }
                  form={"step8"}
                />
              )}
            </div>
          )}
        </div>
      </footer>
      {modal && (
        <MandatoryFieldsModal setModal={setModal} skipStep={handleNext} />
      )}
    </Layout>
  );
};

export default Prestudy;

import { Link } from "react-router-dom";
import {
  lawyerSidemenuItems,
  sideMenuItems,
  universitySideMenuItems,
} from "utils/data";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { setSidebar, userSelector } from "store/slices/User";
import { useLocation } from "react-router-dom";

const Sidebar: React.FC = () => {
  const { sidebarOpen, user } = useAppSelector(userSelector);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  type itemType = {
    icon: string;
    name: string;
    route?: string;
    activeIcon: string;
    childRoutes?: string[] | null;
  };
  type menuItemsMapType = {
    [key: string]: itemType[];
  };

  const setIcon = (data: itemType): string => {
    if (data.route === "/pre-study" && user?.status !== "approved")
      return data.icon;
    if (data.route === pathname) return data.activeIcon;
    else if (data.childRoutes && data.childRoutes.includes(pathname))
      return data.activeIcon;
    else return data.icon;
  };
  const menuItemsMap: menuItemsMapType = {
    student: sideMenuItems,
    university: universitySideMenuItems,
    lawyer: lawyerSidemenuItems,
  };
  const items = user && user.role ? menuItemsMap[user.role] || [] : [];
  return (
    <>
      <div className="flex flex-col items-start space-y-4">
        <span
          className={`font-semibold text-[#71859A] mt-2 uppercase ${
            sidebarOpen ? "ml-5" : "ml-3"
          }`}
        >
          Main
        </span>
        {items.map((item: itemType, i) => (
          <div
            className={`flex py-1 rounded-m justify-center items-center ${
              sidebarOpen ? "px-8" : "px-5"
            }`}
            key={item.name}
          >
            <Link
              to={
                item.route
                  ? item.route === "/pre-study" && user?.status !== "approved"
                    ? ""
                    : item.route
                  : ""
              }
              className="flex"
              onClick={() => {
                if (window.innerWidth < 1024) {
                  dispatch(setSidebar(window.innerWidth > 1024));
                }
              }}
            >
              <img
                src={setIcon(item)}
                className={`cursor-pointer`}
                alt="icon"
              />
              <h2
                className={`whitespace-pre ml-2 font-semibold
                ${
                  !(
                    item.route === "/pre-study" && user?.status !== "approved"
                  ) &&
                  (pathname === item.route ||
                    (item.childRoutes &&
                      item.childRoutes.includes(pathname))) &&
                  "text-[#0A4A5E]"
                }
                 ${!sidebarOpen && "opacity-0 translate-x-28 overflow-hidden"}`}
              >
                {item.name}
              </h2>
              <h2
                className={`${
                  sidebarOpen && "hidden"
                } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
              >
                {item.name}
              </h2>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default Sidebar;

import { Button } from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { clearPrestudy, prestudySelector, setSubmitSignature } from "store/slices/Prestudy";
import SignatureCanvas from "react-signature-canvas";
import { cleanAndLogout, convertbas64Tofile } from "utils/Helper";
import { submitSchema } from "schema/Prestudy";
import { useNavigate } from "react-router-dom";
import { stepProps } from "utils/types";
import moment from "moment";
import { clearUser, userSelector } from "store/slices/User";
import prestudyService from "services/prestudy";
import { toast } from "react-toastify";
import CustomLoader from "components/Loader/CustomLoader";
import { clearFormcorrection } from "store/slices/FormCorrection";
import debounce from "lodash.debounce";

const SubmitForm: React.FC<stepProps> = ({ submitStepdata }) => {
  const navigate = useNavigate();
  const initialValues = {
    signature: "",
  };
  const SignaturePad = SignatureCanvas as any;
  const { currentStep, qrImage, submitSignature } =
    useAppSelector(prestudySelector);
  const sigCanvas = useRef<any>(null);
  const { user } = useAppSelector(userSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const imageDataUrl = submitSignature;
    const img = new Image();
    img.src = submitSignature;
    img.onload = () => {
      if (sigCanvas.current) {
        sigCanvas.current.clear();
        sigCanvas.current.fromDataURL(imageDataUrl);
      }
    };
  }, [sigCanvas]);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (submitSignature && sigCanvas.current) {
        sigCanvas.current.clear();
        sigCanvas.current.fromDataURL(submitSignature);
      }
    }, 300);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [submitSignature]);

  const handleSubmit = async (values: any) => {
    const formData = new FormData();
    formData.append("finalSignature", values.signature);
    user && formData.append("userId", user?._id);
    currentStep && formData.append("step", (currentStep - 1).toString());
    formData.append("label", "Sign your application");
    let response = await prestudyService.uploadDocument(formData);
    const {
      data: { success, status },
    } = response;
    if (success) {
      navigate("/dashboard");
    }
    if (status === 401) {
      setLoading(false);
      dispatch(clearUser());
      dispatch(clearPrestudy());
      dispatch(clearFormcorrection());
      await cleanAndLogout();
      return false;
    }
  };

  const sendEmail = async () => {
    try {
      setLoading(true);
      if (user?.email) {
        let response = await prestudyService.sendEmail(user?.email);
        const {
          data: { message, success, status },
        } = response;
        if (success) {
          setLoading(false);
          if (message) {
            toast(message, { className: "toast-success" });
          }
        }
        if (status === 401) {
          setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const sendSMS = async () => {
    try {
      setLoading(true);
      if (user?.phoneNumber) {
        let response = await prestudyService.sendSms(user?.phoneNumber);
        const {
          data: { message, success, status },
        } = response;
        if (success) {
          setLoading(false);
          if (message) {
            toast(message, { className: "toast-success" });
          }
        }
        if (status === 401) {
          setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full">
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full">
          <div className="radio-sections max-w-full bg-[#E8F8FD] p-5">
            <label className="text-[20px] font-semibold">
              Date & final signature
            </label>
          </div>
          <div className="p-4">
            <div className="qr-section">
              <img src={qrImage} alt="QrImage" className="mb-3" />
              <p className="text-[#71859A] text-[15px] my-5 mr-5">
                You need to sign the application form with a TOUCH SCREEN in
                order to have a better quality of signature. To do so, you can
                scan the QR-Code with your mobile OR send the link to you by
                email OR by SMS by clicking the following buttons.
              </p>
            </div>
            <div className="buttons flex flex-col lg:flex-row xl:flex-row 2xl:flex-row gap-5">
              <Button
                type="submit"
                label={`Receive the link per Email to ${user?.email}`}
                disabled={false}
                cssClass="bg-[#1492B8] text-[15px] text-[#FFFFFF] py-2 lg:w-[241px] w-[220px] rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                handleClick={sendEmail}
              />
              <Button
                type="submit"
                label={`Receive the link per SMS to ${"+" + user?.phoneNumber}`}
                disabled={false}
                cssClass="bg-[#1492B8] text-[15px] text-[#FFFFFF] py-2 lg:w-[241px] w-[220px]  rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                handleClick={sendSMS}
              />
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={submitSchema}
              onSubmit={handleSubmit}
            >
              {({ values }: any) => (
                <Form id={currentStep.toString()}>
                  <div className="date-signature mt-5 mb-4">
                    <div className="mb-2 mt-4 flex justify-between items-center">
                      <label
                        htmlFor={"signature"}
                        className="text-[16px] text-[#0B0D0F] font-semibold"
                      >
                        Date of signature (automatic field)
                        <span className="text-[#FF4444] ml-1">*</span>
                      </label>
                    </div>
                    <div className="lg:w-1/3 xl:w-1/3 2xl:w-1/3 w-full">
                      <div className="flex border-[2px] border-[#E1E5EA] rounded-[12px] py-1 max-w-full">
                        <input
                          type="date"
                          value={moment(new Date()).format("YYYY-MM-DD")}
                          className={"password-input border-none"}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="mb-2 mt-4 flex justify-between items-center">
                      <label
                        htmlFor={"signature"}
                        className="font-semibold text-[16px] text-[#0B0D0F]"
                      >
                        Sign your application
                        <span className="text-[#FF4444] ml-1">*</span>
                      </label>
                    </div>
                    <div className="signature__pad border-[2px] border-[#E1E5EA] rounded-md lg:w-[550px] lg:h-[138px] w-full">
                      <Field name="signature">
                        {({ field, form }: FieldProps) => (
                          <>
                            <SignaturePad
                              {...field}
                              penColor="black"
                              canvasProps={{
                                style: { width: "100%", height: "100%" },
                              }}
                              ref={sigCanvas}
                              onEnd={async () => {
                                const yourBase64 = sigCanvas?.current
                                  ?.getTrimmedCanvas()
                                  .toDataURL("image/png");
                                const signature = await convertbas64Tofile(
                                  yourBase64
                                );
                                dispatch(setSubmitSignature(yourBase64));
                                form.setFieldValue("signature", signature);
                              }}
                            />
                            <u
                              className="float-right mt-1 mr-2 cursor-pointer text-[#71859A] font-semibold"
                              onClick={() => {
                                sigCanvas?.current?.clear();
                                form.setFieldValue("signature", "");
                              }}
                            >
                              clear
                            </u>
                          </>
                        )}
                      </Field>
                    </div>
                    <ErrorMessage
                      name="signature"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubmitForm;

import { Button } from "components/Form";
import React, { Dispatch, SetStateAction } from "react";

type modalProps = {
  setModal: Dispatch<SetStateAction<boolean>>;
  skipStep: () => void;
};
const MandatoryFieldsModal: React.FC<modalProps> = ({
  setModal,
  skipStep
}) => {
    return (
      <div className="fixed inset-0 z-[999999] flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg max-w-full p-4 lg:w-[30%] sm:max-w-[50%] border-[2px] border-[#C3CCD5] mx-3">
          <h2 className="text-xl font-bold  w-fit mx-auto">Mandatory Fields</h2>
          <div className="instructions text-center text-[14px] my-8 font-semibold">
            <p>
              Some mandatory fields are not filled out. Do you want to fill them
              now or continue to the next step.
            </p>
            <p className="mt-4">In any cases,you will have to fill them out.</p>
          </div>
          <div className="flex  focus:outline-none font-semibold justify-center gap-3 text-[14px]">
            <Button
              type="submit"
              label="Fill them now"
              disabled={false}
              cssClass="border-[2px] bg-[#0A4A5E] border-[#0A4A5E] text-white hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed border-none px-4 py-2 rounded-lg"
              handleClick={() => {
                setModal(false)
              }}
            />
            <Button
              type="submit"
              label="Continue"
              disabled={false}
              cssClass="border-[2px] bg-[#FF4444] border-[#FF4444] text-white hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed border-none px-4 py-2 rounded-lg"
              handleClick={() => {
                setModal(false)
                skipStep()
              }}
            />
          </div>
        </div>
      </div>
    );
};

export default MandatoryFieldsModal;

import React, { ChangeEvent, useEffect, useState } from "react";
import {
  CustomDateInput,
  Input,
  Inputfile,
  SelectInput,
} from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { stepthreeSchema } from "schema/Prestudy";
import DatePicker from "react-datepicker";
import { stepDataType, stepProps, stepThreeTypes } from "utils/types";
import { documentTypeOptions } from "utils/data";
import { crossBlack, documentBlack } from "utils/Images";
import { useAppSelector } from "store/Hooks";
import { prestudySelector } from "store/slices/Prestudy";
import { validateFiles } from "utils/Helper";
import moment from "moment";
import { userSelector } from "store/slices/User";

const StepThree: React.FC<stepProps> = ({ submitStepdata }) => {
  const DateInput = DatePicker as any;
  const { currentStep, stepData } = useAppSelector(prestudySelector);
  const [fileError, setfileError] = useState<string | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const { user } = useAppSelector(userSelector);
  const [stepFields, setStepFields] = useState<stepThreeTypes>({
    file: [],
    docType: "",
    authority: "",
    docNumber: "",
    issueDate: null,
    validDate: null,
  });

  const initialValues: stepThreeTypes = {
    file: stepFields.file ? stepFields.file : [],
    docType: stepFields.docType ? stepFields.docType : "",
    authority: stepFields.authority ? stepFields.authority : "",
    docNumber: stepFields.docNumber ? stepFields.docNumber : "",
    issueDate: stepFields.issueDate ? stepFields.issueDate : null,
    validDate: stepFields.validDate ? stepFields.validDate : null,
  };

  const uploadFiles = async (selectedFile: FileList | null) => {
    const allowedTypes = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
    const isValidate = await validateFiles(selectedFile, allowedTypes);
    if (isValidate === "true") {
      if (selectedFile) {
        setFiles(Array.from(selectedFile));
      }
    } else {
      setFiles(null);
      setfileError(isValidate);
    }
  };

  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof stepThreeTypes;
      switch (name) {
        case "docType":
        case "authority":
        case "docNumber":
          updatedFields[name] = item.value;
          break;
        case "issueDate":
        case "validDate":
          if (typeof item.value === "string" && item.value !== "") {
            updatedFields[name] = moment(item.value).format("YYYY-MM-DD");
          }
          break;
        case "file":
          updatedFields[name] =
            item.value instanceof Array ? [...item.value] : item.value;
          break;
        default:
          break;
      }
    });
    setStepFields(updatedFields);
  }, [stepData]);

  const removeFile = (fileName: string) => {
    const updatedFiles = files?.filter((item, index) => item.name !== fileName);
    if (updatedFiles && updatedFiles.length === 0)
      setfileError("Files are required");
    updatedFiles && setFiles(updatedFiles);
  };
  const handleSubmit = async (values: stepThreeTypes) => {
    if (!fileError) {
      const formData = new FormData();
      if (!values?.file[0].hasOwnProperty('location')) {
        values?.file?.forEach((file :any) => {
          formData.append("file", file);
        });
        user && formData.append("userId", user?._id);
        currentStep && formData.append("step", (currentStep - 1).toString());
        formData.append(
          "label",
          "Upload a COLOR copy of your passport (details page with picture and passport number)"
        );
      }
      let payload = [
        {
          name: "docType",
          label: "Document Type",
          value: values.docType,
          isShow: true,
        },
        {
          name: "docNumber",
          label: "Document Number",
          value: values.docNumber,
          isShow: true,
        },
        {
          name: "authority",
          label: "Issuing Authority",
          value: values.authority,
          isShow: true,
        },
        {
          name: "issueDate",
          label: "Date of Issue",
          value: new Date(
            moment(values.issueDate).format("YYYY/MM/DD")
          ).toISOString(),
          isShow: true,
        },
        {
          name: "validDate",
          label: "Valid Until Date",
          value: new Date(
            moment(values.validDate).format("YYYY/MM/DD")
          ).toISOString(),
          isShow: true,
        },
      ];
      const uniqueNames: { [name: string]: boolean } = {};
      let updatedArr: stepDataType[] = [];
      payload.map((item) => {
        let itemName = item.name as keyof stepThreeTypes;
        const oldValue = stepFields[itemName];
        if (oldValue && oldValue !== item.value) {
          if (!uniqueNames[item.name]) {
            uniqueNames[item.name] = true;
            updatedArr.push({ ...item, value: item.value });
          }
        }
      });
      await submitStepdata(
        updatedArr?.length > 0 ? updatedArr : payload, 
        !values?.file[0].hasOwnProperty('location') ? formData : null
      );
    }
  };
  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full">
      <label className="text-[#0B0D0F] font-semibold text-[20px]">
        Passport
      </label>
      <p className="text-[#71859A] leading-[27.36px] text-[15px] mt-5 lg:w-1/2 xl:w-1/2 w-full">
        Make sure your fingers are not visible on the copy you upload{" "}
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={stepthreeSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleChange }: any) => (
          <Form className="mx-auto" id={currentStep.toString()}>
            <>
              <div className={"block"}>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"email"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Upload a COLOR copy of your passport (details page with
                    picture and passport number){" "}
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="file">
                  {({ field, form }: FieldProps) => (
                    <>
                      <Inputfile
                        {...field}
                        mainClass="lg:w-1/2 xl:w-1/2 w-full"
                        btnClass={null}
                        handleChange={(
                          event: ChangeEvent<HTMLInputElement>
                        ) => {
                          const selectedFile = event.target.files;
                          selectedFile &&
                            form.setFieldValue(
                              "file",
                              Array.from(selectedFile)
                            );
                          setfileError(null);
                          uploadFiles(event.target.files);
                        }}
                        multiple={true}
                      />
                      {values.file &&
                        values.file.length > 0 &&
                        values.file.map(
                          (item: { name: string; location: string }) => (
                            <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 overflow-hidden">
                              <img src={documentBlack} alt="documentBlack" />
                              <label className="flex-1">{item.name}</label>
                              <img
                                src={crossBlack}
                                alt="documentBlack"
                                onClick={() => {
                                  const updatedFiles = values.file?.filter(
                                    (val: { name: string; location: string }) =>
                                      val.name !== item.name
                                  );
                                  form.setFieldValue("file", updatedFiles);
                                  if (updatedFiles.length === 0) {
                                    form.setFieldValue("file", null);
                                    setfileError("Files are required");
                                  }
                                }}
                                className="cursor-pointer"
                              />
                            </div>
                          )
                        )}
                    </>
                  )}
                </Field>
                {fileError ? (
                  <div className="text-red-500 text-sm md:text-base fileError">
                    {fileError}
                  </div>
                ) : (
                  <ErrorMessage
                    name="file"
                    component="div"
                    className="text-red-600 fileError"
                  />
                )}

                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={"block"}>
                <p className="mt-3 text-[#71859A] text-[15px]">
                  The residence permit application procedure depends on your
                  current address.{" "}
                </p>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"gender"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Document Type <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <div className="lg:w-1/2 xl:w-1/2 w-full">
                  <Field name="docType">
                    {({ field, form }: FieldProps) => (
                      <SelectInput
                        {...field}
                        fieldName="docType"
                        options={documentTypeOptions}
                        selectedOption={values.docType}
                        handleSelect={(
                          event: ChangeEvent<HTMLInputElement>
                        ) => {
                          form.setFieldValue("docType", event.target.value);
                        }}
                        className="w-full"
                      />
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name={"docType"}
                  component="div"
                  className="text-red-500"
                />
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={"block"}>
                <p className="mt-3 text-[#71859A] text-[15px]">
                  If the issuing authority is not mentioned in your passport,
                  write the PLACE of the passport issuance
                </p>
                <p className="text-[#71859A] text-[15px]">
                  You find the issuing authority usually on the details page of
                  your passport{" "}
                </p>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"authority"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Issuing Authority{" "}
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <div className="lg:w-1/2 xl:w-1/2 w-full">
                  <Input
                    type="text"
                    fieldName="authority"
                    placeholder="Unknown"
                    setValue={values.authority}
                    className={null}
                    handleChange={handleChange}
                  />
                </div>
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={"block"}>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"gender"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Document Number{" "}
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <div className="lg:w-1/2 xl:w-1/2 w-full">
                  <Field name="docNumber">
                    {({ field, form }: FieldProps) => (
                      <Input
                        type="text"
                        fieldName="docNumber"
                        placeholder="Document Number"
                        setValue={values.docNumber}
                        className={null}
                        handleChange={handleChange}
                      />
                    )}
                  </Field>
                </div>
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={"block"}>
                <div className="my-2 flex justify-between items-center">
                  <label
                    htmlFor={"issueDate"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Date of Issue
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="issueDate">
                  {({ field, form }: FieldProps) => (
                    <DateInput
                      {...field}
                      customInput={<CustomDateInput isCalendar={true} />}
                      dateFormat="yyyy-MM-dd"
                      selected={field.value}
                      onChange={(date: Date) => {
                        form.setFieldValue("issueDate", date);
                      }}
                      className={"lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full"}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="issueDate"
                  component="div"
                  className="text-red-600"
                />
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={"block"}>
                <div className="my-2 flex justify-between items-center">
                  <label
                    htmlFor={"validDate"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Valid Until Date
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="validDate">
                  {({ field, form }: FieldProps) => (
                    <DateInput
                      {...field}
                      customInput={<CustomDateInput isCalendar={true} />}
                      dateFormat="yyyy-MM-dd"
                      selected={field.value}
                      onChange={(date: Date) => {
                        form.setFieldValue("validDate", date);
                      }}
                      className={"lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full"}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="validDate"
                  component="div"
                  className="text-red-600"
                />
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepThree;

import { Button } from "components/Form";
import React, { Dispatch, SetStateAction } from "react";
import { useAppDispatch } from "store/Hooks";
import { setUser } from "store/slices/User";
import { cleanAndLogout } from "utils/Helper";

type modalProps = {
  setModal: Dispatch<SetStateAction<boolean>>;
};

const LogoutModal: React.FC<modalProps> = ({ setModal }) => {
  const dispatch = useAppDispatch();

  const logout = async () => {
    dispatch(setUser(null));
    await cleanAndLogout();
  };
  return (
    <div className="fixed inset-0 z-[9999999] flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg max-w-full w-[498px] md:w-[398px] sm:w-[350px]  border-[2px] border-[#C3CCD5] lg:mx-0 mx-2 lg:mt-0 mt-[5.5rem]">
        <div className="flex flex-col my-10">
          <h2 className="text-base md:text-xl font-bold text-[#0B0D0F] flex items-center justify-center">
            Do you really want to log out?
          </h2>
          <div className="buttons text-[14px] flex items-center justify-center gap-[10px]">
            <Button
              type="submit"
              label="Confirm"
              disabled={false}
              cssClass="bg-[#E39D6B] text-[15px] text-[#FFFFFF] py-3 xs:w-[141px] md:w-[171px] rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed mt-5"
              handleClick={logout}
            />
            <Button
              type="submit"
              label="Cancel"
              disabled={false}
              cssClass="bg-[#0A4A5E] text-[15px] text-[#FFFFFF] py-3 xs:w-[141px] md:w-[171px] rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed mt-5"
              handleClick={() => setModal(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;

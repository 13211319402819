import React, { ChangeEvent, useEffect, useState } from "react";
import { CustomDateInput, Inputfile } from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { stepFourSchema } from "schema/Prestudy";
import DatePicker from "react-datepicker";
import { stepFourTypes, stepProps } from "utils/types";
import { crossBlack, documentBlack, stamp } from "utils/Images";
import { prestudySelector } from "store/slices/Prestudy";
import { useAppSelector } from "store/Hooks";
import { validateFiles } from "utils/Helper";
import moment from "moment";
import { userSelector } from "store/slices/User";
import { CountryDropdown } from "react-country-region-selector";

const StepFour: React.FC<stepProps> = ({ submitStepdata }) => {
  const DateInput = DatePicker as any;
  const CountrySelect = CountryDropdown as any;
  const [stepFields, setStepFields] = useState<stepFourTypes>({
    stampFile: [],
    date: null,
    lastEntrycity: "",
  });
  const initialValues: stepFourTypes = {
    stampFile: stepFields.stampFile ? stepFields.stampFile : [],
    date: stepFields.date ? stepFields.date : null,
    lastEntrycity: stepFields.lastEntrycity ? stepFields.lastEntrycity : "",
  };
  const [fileError, setfileError] = useState<string | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const { currentStep, stepData } = useAppSelector(prestudySelector);
  const { user } = useAppSelector(userSelector);

  const handleSubmit = async (values: stepFourTypes) => {
    if (!fileError) {
      const formData = new FormData();
      if (typeof values?.stampFile === "object") {
        values?.stampFile?.forEach((file:any) => {
          formData.append("stampFile", file);
        });
        user && formData.append("userId", user?._id);
        currentStep && formData.append("step", (currentStep - 1).toString());
        formData.append(
          "label",
          "Upload a copy of your last ENTRY STAMP in the Schengen area (including Germany)"
        );
      }
      let payload = [
        {
          name: "date",
          label:
            "Enter the date of your “last” entry in the Schengen area (including Germany)",
          value: new Date(
            moment(values.date).format("YYYY/MM/DD")
          ).toISOString(),
          isShow: true,
        },
        {
          name: "lastEntrycity",
          label: "From",
          value: values.lastEntrycity,
          isShow: true,
        },
      ];
      await submitStepdata(
        payload,
        typeof values?.stampFile === "object" ? formData : null
      );
    }
  };

  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof stepFourTypes;
      switch (name) {
        case "lastEntrycity":
          updatedFields[name] = item.value;
          break;
        case "date":
          if (typeof item.value === "string" && item.value !== "") {
            updatedFields[name] = moment(item.value).format("YYYY-MM-DD");
          }
          break;
        case "stampFile":
          updatedFields[name] =
            item.value instanceof Array ? item.value[0]?.name : item.value;
          break;
        default:
          break;
      }
    });
    setStepFields(updatedFields);
  }, [stepData]);

  const removeFile = (fileName: string) => {
    const updatedFiles = files?.filter((item, index) => item.name !== fileName);
    if (updatedFiles && updatedFiles.length === 0)
      setfileError("Files are required");
    updatedFiles && setFiles(updatedFiles);
  };

  const uploadFiles = async (selectedFile: FileList | null) => {
    const allowedTypes = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
    const isValidate = await validateFiles(selectedFile, allowedTypes);
    if (isValidate === "true") {
      if (selectedFile) {
        setFiles(Array.from(selectedFile));
      }
    } else {
      setFiles(null);
      setfileError(isValidate);
    }
  };
  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full">
      <label className="text-[#0B0D0F] font-semibold text-[20px]">
        Last entry in the Schengen area (including Germany)
      </label>
      <Formik
        initialValues={initialValues}
        validationSchema={stepFourSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values }: any) => (
          <Form className="mx-auto" id={currentStep.toString()}>
            <>
              <div className="flex flex-col gap-6 my-6">
                <h3 className="text-[#71859A] text-[14px]">
                  Here is an example of last entry stamp in Schengen area{" "}
                </h3>
                <div className="w-3/4">
                  <img src={stamp} alt="image" />
                </div>
              </div>

              <div className={"block"}>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"email"}
                    className="font-400 text-[14px] text-[#0B0D0F]"
                  >
                    Upload a copy of your last ENTRY STAMP in the Schengen area
                    (including Germany)
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="stampFile">
                  {({ field, form }: FieldProps) => (
                    <>
                      <Inputfile
                        {...field}
                        mainClass="lg:w-1/2 xl:w-1/2 w-full"
                        btnClass={null}
                        handleChange={(
                          event: ChangeEvent<HTMLInputElement>
                        ) => {
                          let selectedFile: FileList | null =
                            event.target.files;
                          selectedFile &&
                            form.setFieldValue(
                              "stampFile",
                              Array.from(selectedFile)
                            );
                          setfileError(null);
                          uploadFiles(event.target.files);
                        }}
                        multiple={false}
                      />
                      {typeof values.stampFile === "string" && (
                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 overflow-hidden">
                          <img src={documentBlack} alt="documentBlack" />
                          <label className="flex-1">{values.stampFile}</label>
                          <img
                            src={crossBlack}
                            alt="documentBlack"
                            onClick={() => {
                              form.setFieldValue("stampFile", null);
                              setfileError("Files are required");
                            }}
                            className="cursor-pointer"
                          />
                        </div>
                      )}
                    </>
                  )}
                </Field>
                {files &&
                  files?.map((item) => (
                    <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 overflow-hidden">
                      <img src={documentBlack} alt="documentBlack" />
                      <label className="flex-1">{item.name}</label>
                      <img
                        src={crossBlack}
                        alt="documentBlack"
                        onClick={() => removeFile(item.name)}
                        className="cursor-pointer"
                      />
                    </div>
                  ))}
                {fileError ? (
                  <div className="text-red-500 text-sm md:text-base fileError">
                    {fileError}
                  </div>
                ) : (
                  <ErrorMessage
                    name="stampFile"
                    component="div"
                    className="text-red-600 fileError"
                  />
                )}
              </div>
              <div className={"block"}>
                <hr className="bg-[#E1E5EA] my-5" />
                <p className="mt-3 text-[#71859A] text-[15px]">
                  The residence permit application procedure depends on your
                  current address.{" "}
                </p>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"gender"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Enter the date of your “last” entry in the Schengen area
                    (including Germany){" "}
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="date">
                  {({ field, form }: FieldProps) => (
                    <DateInput
                      {...field}
                      customInput={<CustomDateInput isCalendar={true} />}
                      dateFormat="yyyy-MM-dd"
                      selected={field.value}
                      onChange={(date: Date) => {
                        form.setFieldValue("date", date);
                      }}
                      className={"lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full"}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="date"
                  component="div"
                  className="text-red-600"
                />
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={"block"}>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"gender"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    From <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <div className="lg:w-1/2 xl:w-1/2 w-full">
                  <Field name="lastEntrycity">
                    {({ field, form }: FieldProps) => (
                      <CountrySelect
                        {...field}
                        value={values.lastEntrycity}
                        onChange={(val: string) =>
                          form.setFieldValue("lastEntrycity", val)
                        }
                        className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                      />
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name={"lastEntrycity"}
                  component="div"
                  className="text-red-500"
                />
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepFour;

import React from "react";
import * as menu from "@radix-ui/react-context-menu";
import { rightArrow } from "utils/Images";
import { changeAttributesMenu, docs, form, sendEmailMenu } from "utils/data";

type item = {
  id: string;
  value: string;
  hasSubmenu: boolean;
};

type subItem = {
  id: string;
  value: string;
};
interface ContextMenuListProps {
  menuItems: item[];
  studentId: string;
}
const ContextMenuList: React.FC<ContextMenuListProps> = ({
  menuItems,
  studentId,
}) => {
  const renderSubmenu = (name: string) => {
    switch (name) {
      case "attributes":
        return changeAttributesMenu.map((val: subItem) => (
          <menu.Item className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer">
            {val.value}
          </menu.Item>
        ));
      case "email":
        return sendEmailMenu.map((val: subItem) => (
          <menu.Item className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer">
            {val.value}
          </menu.Item>
        ));
      case "openDocs":
        return docs.map((val: subItem) => (
          <menu.Item className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer">
            {val.value}
          </menu.Item>
        ));
      case "uploadDocs":
        return docs.map((val: subItem) => (
          <menu.Item className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer">
            {val.value}
          </menu.Item>
        ));
      case "openForm":
        return form.map((val: subItem) => (
          <menu.Item className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer">
            {val.value}
          </menu.Item>
        ));
      default:
        return null;
    }
  };
  return (
    <menu.Portal>
      <menu.Content className="min-w-[220px] bg-white rounded-md overflow-hidden p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
        <menu.Item className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer font-semibold">
          {studentId}
        </menu.Item>
        <menu.Separator className="h-[1px] bg-[#E1E5EA] m-[5px]" />
        {menuItems.map((item) =>
          item.hasSubmenu ? (
            <menu.Sub>
              <menu.SubTrigger className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[state=open]:bg-violet4 data-[state=open]:text-violet11 data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 data-[highlighted]:data-[state=open]:bg-violet9 data-[highlighted]:data-[state=open]:text-violet1 cursor-pointer">
                {item.value}
                <div className="ml-auto pl-5 text-mauve11 group-data-[highlighted]:text-white group-data-[disabled]:text-mauve8">
                  <img src={rightArrow} alt="right-arrow" />
                </div>
              </menu.SubTrigger>
              <menu.Portal>
                <menu.SubContent
                  className="min-w-[220px] bg-white rounded-md overflow-hidden p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]"
                  sideOffset={2}
                  alignOffset={-5}
                >
                  {renderSubmenu(item.id)}
                </menu.SubContent>
              </menu.Portal>
            </menu.Sub>
          ) : (
            <>
              <menu.Item className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer">
                {item.value}
              </menu.Item>
            </>
          )
        )}
      </menu.Content>
    </menu.Portal>
  );
};

export default ContextMenuList;

import {
  appointment,
  clipboardActive,
  clipbord,
  faq,
  faqActive,
  formActive,
  home,
  homeActive,
  list,
  listActive,
  profile,
  profileActive,
  setting,
  settingActive,
} from "utils/Images";

export const sideMenuItems = [
  {
    icon: home,
    activeIcon: homeActive,
    name: "Dashboard",
    route: "/dashboard",
    childRoutes: null,
  },
  {
    icon: profile,
    activeIcon: profileActive,
    name: "Profile",
    route: "/profile",
    childRoutes: null,
  },
  {
    icon: clipbord,
    activeIcon: formActive,
    name: "Form 1",
    route: "/pre-study",
    childRoutes: null,
  },
  {
    icon: faq,
    activeIcon: faqActive,
    name: "FAQ",
    route: "/faq",
    childRoutes: null,
  },
];

export const universitySideMenuItems = [
  {
    icon: home,
    activeIcon: homeActive,
    name: "Dashboard",
    route: "/university-dashboard",
    childRoutes: null,
  },
  {
    icon: profile,
    activeIcon: profileActive,
    name: "Open",
    route: "/approval-open",
    childRoutes: ["/review-form"],
  },
  {
    icon: clipbord,
    activeIcon: clipboardActive,
    name: "Documents",
    route: "/approval-document",
    childRoutes: ["/send-document"],
  },
  {
    icon: setting,
    activeIcon: settingActive,
    name: "Active Services",
    route: "/active-services",
    childRoutes: ["/overview"],
  },
];

export const lawyerSidemenuItems = [
  {
    icon: home,
    activeIcon: homeActive,
    name: "Dashboard",
    route: "/lawyer-dashboard",
    childRoutes: null,
  },
  {
    icon: list,
    activeIcon: listActive,
    name: "List",
    route: "/service-listing",
    childRoutes: ["/correction"],
  },
  {
    icon: appointment,
    activeIcon: appointment,
    name: "Appointment",
    route: "/appointment",
    childRoutes: null,
  },
];

export const options = [
  {
    id: "1",
    name: "visa",
    value: "german-entry-visa",
    label:
      "German Study Entry Visa (issued by the German Embassy in your home country)",
  },
  {
    id: "2",
    name: "visa",
    value: "free-entry-visa",
    label: "90-days free entry allowance",
  },
];

export const addressOptions = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

export const visaOptions = [
  {
    value: "You need an entry visa",
    label: "You need an entry visa",
  },
  {
    value: "You DO NOT need an entry visa",
    label: "You DO NOT need an entry visa",
  },
  {
    value: "You have no restriction to be on the German territory",
    label: "You have no restriction to be on the German territory",
  },
];

export const statusOptions = [
  {
    value: "Study Entry Visa (issued by the Embassy in your home country)",
    label: "Study Entry Visa (issued by the Embassy in your home country)",
  },
  {
    value: "90-days free entry allowance",
    label: "90-days free entry allowance",
  },
  {
    value:
      "Residence permit for study preparation (Visa already extended with the immigration office IN Germany)",
    label:
      "Residence permit for study preparation (Visa already extended with the immigration office IN Germany)",
  },
  {
    value:
      "Residence permit for study (Visa already extended with the immigration office IN Germany",
    label:
      "Residence permit for study (Visa already extended with the immigration office IN Germany",
  },
  {
    value:
      "Fictional Certificate (issued by the immigration office in Germany)",
    label:
      "Fictional Certificate (issued by the immigration office in Germany)",
  },
  {
    value: "Other immigration status",
    label: "Other immigration status",
  },
];

export const genderOptions = [
  { value: "", label: "Select Gender" },
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const cityOptions = [
  { value: "", label: "Select city" },
  { value: "Berlin", label: "Berlin" },
  { value: "Potsdam", label: "Potsdam" },
  { value: "Other", label: "Other" },
];

export const zipcodeOption = [
  { value: "", label: "Select Code" },
  { value: "option1", label: "Option 1" },
  { value: "option2", label: "Option 2" },
  { value: "option3", label: "Option 3" },
];

export const documentTypeOptions = [
  { value: "", label: "Select Type" },
  { value: "Passport", label: "Passport" },
  { value: "Visa", label: "Visa" },
];

export const documentTypeNumbers = [
  { value: "123", label: "123" },
  { value: "456", label: "456" },
  { value: "789", label: "789" },
];

export const maritalOptions = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
  { value: "Divorced", label: "Divorced" },
  { value: "Widowed", label: "Widowed" },
];

export const VEDcontextMenu = [
  {
    id: "attributes",
    value: "Change Attributes",
    hasSubmenu: true,
  },
  {
    id: "invoice",
    value: "Add info invoice",
    hasSubmenu: false,
  },
  {
    id: "VED",
    value: "Check VED",
    hasSubmenu: false,
  },
  {
    id: "call",
    value: "Call +49123123123",
    hasSubmenu: false,
  },
  {
    id: "email",
    value: "Send Email",
    hasSubmenu: true,
  },
];

export const changeAttributesMenu = [
  {
    id: "studentId",
    value: "Q1231234",
  },
  {
    id: "status",
    value: "Status",
  },
  {
    id: "proceeds",
    value: "Proceeds",
  },
  {
    id: "correctionStatus",
    value: "Correction Status",
  },
  {
    id: "city",
    value: "City",
  },
  {
    id: "other",
    value: "More...",
  },
];

export const sendEmailMenu = [
  {
    id: "LEA",
    value: "LEA delay",
  },
  {
    id: "temp1",
    value: "Template 1",
  },
  {
    id: "temp2",
    value: "Template 2",
  },
  {
    id: "temp3",
    value: "Template 3",
  },
  {
    id: "temp4",
    value: "Template 4",
  },
];

export const reminderMenuItems = [
  ...VEDcontextMenu,
  {
    id: "reminder",
    value: "Send reminder",
    hasSubmenu: false,
  },
];

export const readyMenuItems = [
  ...VEDcontextMenu,
  {
    id: "uniDocs",
    value: "Send uni docs reminder",
    hasSubmenu: true,
  },
  {
    id: "correct",
    value: "Correct",
    hasSubmenu: false,
  },
  {
    id: "uploadDocs",
    value: "Upload Docs",
    hasSubmenu: true,
  },
  {
    id: "openDocs",
    value: "Open Docs",
    hasSubmenu: true,
  },
  {
    id: "openForm",
    value: "Open form",
    hasSubmenu: true,
  },
];

export const docs = [
  {
    id: "doc1",
    value: "Doc 1",
  },
  {
    id: "doc2",
    value: "Doc 2",
  },
  {
    id: "doc3",
    value: "Doc 3",
  },
  {
    id: "doc4",
    value: "Doc 4",
  },
  {
    id: "doc5",
    value: "Doc 5",
  },
];

export const form = [
  {
    id: "Step1",
    value: "Step 1",
  },
  {
    id: "Step2",
    value: "Step 2",
  },
  {
    id: "Step3",
    value: "Step 3",
  },
  {
    id: "Step4",
    value: "Step 4",
  },
  {
    id: "Step5",
    value: "Step 5",
  },
  {
    id: "Step6",
    value: "Step 6",
  },
  {
    id: "Step7",
    value: "Step 7",
  },

];
export const authRoutes = [
  "/login",
  "/forgot-password",
  "/auth/reset-password",
  "/register"
];
export const studentRoutes= ["/dashboard","/pre-study","/faq","/profile"];
export const universityRoutes = ["/university-dashboard","/send-document","/overview","/approval-open","/approval-document","/review-form","/review-form","/active-services","/profile"]
export const lawyerRoutes = ["/correction-list","/send-fingerprint-appointment","/send-hs-number","/add-hs-number","/approval-to-student","/send-to-lea","/phone-call","/send-uni-doc-reminder","/send-reminder","/send-email","/check-ved","/add-info-invoice","/correction","/appointment","/service-listing","/lawyer-dashboard","/profile"]
export const cityoptions = [
  { value: "Berlin", label: "Berlin" },
  { value: "Potsdam", label: "Potsdam" },
];

export const processOptions = [
  { value: "pre-study", label: "Study" },
  { value: "post-study", label: "Post-Study" },
];

import {
  Outlet,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import DashboardPage from "pages/Dashboard";
import LoginPage from "pages/Auth/Login";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { clearUser, setUser, userSelector } from "store/slices/User";
import UniversityDashboard from "pages/UniversityDashboard";
import LawyerDashboardPage from "pages/LawyerDashboard";
import PrestudyPage from "pages/Prestudy";
import {
  authRoutes,
  lawyerRoutes,
  studentRoutes,
  universityRoutes,
} from "utils/data";
import Unauthorized from "components/Unauthorized";
import { cleanAndLogout } from "utils/Helper";
import prestudyService from "services/prestudy";
import { clearPrestudy } from "store/slices/Prestudy";
import { clearFormcorrection } from "store/slices/FormCorrection";
import { useEffect, useState } from "react";
import CustomLoader from "components/Loader/CustomLoader";

const AuthenticationGuard = () => {
  const { user } = useAppSelector(userSelector);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const verifyToken = async () => {
    try {
      setLoading(true);
      let token = await localStorage.getItem("token");
      if (token) {
        let response = await prestudyService.verifyToken(token);
        const {
          data: {
            data: { user: userDetails },
            success,
            status,
          },
        } = response;
        if (success) {
          dispatch(
            setUser({ ...userDetails, isAuthenticated: true, isVerified: true })
          );
        }
        if (status === 401) {
          setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("🚀 ~ verifyToken ~ error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.pathname === "/pre-study") {
      let token: any = searchParams.get("token");
      if (token) {
        localStorage.setItem("token", token);
        verifyToken();
      }
    }
  }, []);

  return loading ? (
    <CustomLoader />
  ) : user && user.isAuthenticated ? (
    !authRoutes.includes(location.pathname) ? (
      (studentRoutes.includes(location.pathname) && user.role === "student") ||
      (universityRoutes.includes(location.pathname) &&
        user.role === "university") ||
      (lawyerRoutes.includes(location.pathname) && user.role === "lawyer") ? (
        loading ? (
          <CustomLoader />
        ) : (
          <Outlet />
        )
      ) : (
        <>
          <Navigate to="/unauthorized" />
          <Unauthorized />
        </>
      )
    ) : (
      <>
        {user?.role === "student" &&
          (user?.isVerified ? (
            <>
              <Navigate to="/pre-study" />
              <PrestudyPage />
            </>
          ) : (
            <>
              <Navigate to="/dashboard" />
              <DashboardPage />
            </>
          ))}
        {user?.role === "university" && (
          <>
            <Navigate to="/university-dashboard" />
            <UniversityDashboard />
          </>
        )}
        {user?.role === "lawyer" && (
          <>
            <Navigate to="/lawyer-dashboard" />
            <LawyerDashboardPage />
          </>
        )}
      </>
    )
  ) : authRoutes.includes(location.pathname) ? (
    loading ? (
      <CustomLoader />
    ) : (
      <Outlet />
    )
  ) : loading ? (
    <CustomLoader />
  ) : user && user.isAuthenticated && user.isVerified ? (
    <>
      <Navigate to="/pre-study" />
      <PrestudyPage />
    </>
  ) : loading ? (
    <CustomLoader />
  ) : (
    <>
      <Navigate to="/login" />
      <LoginPage />
    </>
  );
};

export default AuthenticationGuard;

import {
  CustomDateInput,
  Input,
  Inputfile,
  RadioGroup,
  SelectInput,
} from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { stepSevenSchema } from "schema/Prestudy";
import { useAppSelector } from "store/Hooks";
import { prestudySelector } from "store/slices/Prestudy";
import { addressOptions, maritalOptions } from "utils/data";
import { StepSevenTypes, stepDataType, stepProps } from "utils/types";
import DatePicker from "react-datepicker";
import { crossBlack, documentBlack, letter } from "utils/Images";
import moment from "moment";
import { validateFiles } from "utils/Helper";
import { userSelector } from "store/slices/User";
import prestudyService from "services/prestudy";

const StepSeven: React.FC<stepProps> = ({ submitStepdata }) => {
  const DateInput = DatePicker as any;
  const { currentStep, stepData } = useAppSelector(prestudySelector);
  const [stepFields, setStepFields] = useState<StepSevenTypes>({
    germanLastEntry: "",
    authorisation: "",
    residence: "",
    maritalStatus: "",
    children: "",
    father: "",
    mother: "",
    criminality: "",
    germanyRejection: "",
    germanyRejectionReason: "",
    otherRejection: "",
    otherRejectionReason: "",
    subsistence: "",
    subsistenceFile: [],
    healthInsurance: "",
    healthInsuranceFile: [],
    matriculationLetter: "",
    date: "",
    matriculationFile: [],
  });

  const initialValues: StepSevenTypes = {
    germanLastEntry:
      stepFields.germanLastEntry !== "" ? stepFields.germanLastEntry : "",
    authorisation:
      stepFields.authorisation !== "" ? stepFields.authorisation : "",
    residence: stepFields.residence !== "" ? stepFields.residence : "",
    maritalStatus:
      stepFields.maritalStatus !== "" ? stepFields.maritalStatus : "",
    children: stepFields.children !== "" ? stepFields.children : "",
    father: stepFields.father !== "" ? stepFields.father : "",
    mother: stepFields.mother !== "" ? stepFields.mother : "",
    criminality: stepFields.criminality !== "" ? stepFields.criminality : "",
    germanyRejection:
      stepFields.germanyRejection !== "" ? stepFields.germanyRejection : "",
    germanyRejectionReason:
      stepFields.germanyRejectionReason !== ""
        ? stepFields.germanyRejectionReason
        : "",
    otherRejection:
      stepFields.otherRejection !== "" ? stepFields.otherRejection : "",
    otherRejectionReason:
      stepFields.otherRejectionReason !== ""
        ? stepFields.otherRejectionReason
        : "",
    subsistence: stepFields.subsistence !== "" ? stepFields.subsistence : "",
    subsistenceFile: stepFields.subsistenceFile
      ? stepFields.subsistenceFile
      : [],
    healthInsurance:
      stepFields.healthInsurance !== "" ? stepFields.healthInsurance : "",
    healthInsuranceFile: stepFields.healthInsuranceFile
      ? stepFields.healthInsuranceFile
      : [],
    matriculationLetter:
      stepFields.matriculationLetter !== ""
        ? stepFields.matriculationLetter
        : "",
    date: stepFields.date !== "" ? stepFields.date : "",
    matriculationFile: stepFields.matriculationFile
      ? stepFields.matriculationFile
      : [],
  };
  const options = [
    {
      value: "Bachelor, Dual - 36 Months",
      label: "Bachelor, Dual - 36 Months",
    },
  ];
  const [fileError, setfileError] = useState<string | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const [healthfileError, setHealthfileError] = useState<string | null>(null);
  const [healthFiles, setHealthFiles] = useState<File[] | null>(null);
  const [matriculationFiles, setMatriculationFiles] = useState<File[] | null>(
    null
  );
  const { user } = useAppSelector(userSelector);
  const [matriculationFileError, setMatriculationFileError] = useState<
    string | null
  >(null);

  const uploadFiles = async (
    selectedFile: FileList | null,
    fieldName: string
  ) => {
    const allowedTypes = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
    const isValidate = await validateFiles(selectedFile, allowedTypes);
    if (isValidate === "true") {
      if (selectedFile) {
        if (fieldName === "subsistenceFile") {
          setFiles(Array.from(selectedFile));
        }
        if (fieldName === "healthInsuranceFile") {
          setHealthFiles(Array.from(selectedFile));
        }
        if (fieldName === "matriculationFile") {
          setMatriculationFiles(Array.from(selectedFile));
        }
      }
    } else {
      if (fieldName === "subsistenceFile") {
        setFiles(null);
        setfileError(isValidate);
      }
      if (fieldName === "healthInsuranceFile") {
        setHealthFiles(null);
        setHealthfileError(isValidate);
      }
      if (fieldName === "matriculationFile") {
        setMatriculationFiles(null);
        setMatriculationFileError(isValidate);
      }
    }
  };

  const removeFile = async (fileName: string, fieldName: string) => {
    if (fieldName === "subsistenceFile") {
      const updatedFiles = files?.filter((item) => item.name !== fileName);
      if (updatedFiles && updatedFiles.length === 0)
        setfileError("Files are required");
      updatedFiles && setFiles(updatedFiles);
    }
    if (fieldName === "healthInsuranceFile") {
      const updatedFiles = healthFiles?.filter(
        (item) => item.name !== fileName
      );
      if (updatedFiles && updatedFiles.length === 0)
        setHealthfileError("Files are required");
      updatedFiles && setHealthFiles(updatedFiles);
    }
    if (fieldName === "matriculationFile") {
      const updatedFiles = matriculationFiles?.filter(
        (item) => item.name !== fileName
      );
      if (updatedFiles && updatedFiles.length === 0)
        setMatriculationFileError("Files are required");
      updatedFiles && setMatriculationFiles(updatedFiles);
    }
  };

  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof StepSevenTypes;
      switch (name) {
        case "germanLastEntry":
        case "authorisation":
        case "residence":
        case "maritalStatus":
        case "children":
        case "father":
        case "mother":
        case "criminality":
        case "germanyRejection":
        case "otherRejection":
        case "subsistence":
        case "matriculationLetter":
        case "healthInsurance":
        case "otherRejectionReason":
        case "germanyRejectionReason":
          updatedFields[name] = item.value;
          break;
        case "date":
          if (typeof item.value === "string" && item.value !== "") {
            updatedFields[name] = moment(item.value).format("YYYY-MM-DD");
          }
          break;
        case "subsistenceFile":
        case "matriculationFile":
          updatedFields[name] =
            item.value instanceof Array ? item.value[0]?.name : item.value;
          break;
        case "healthInsuranceFile":
          updatedFields[name] =
            item.value instanceof Array ? [...item.value] : item.value;
          break;

        default:
          break;
      }
    });
    setStepFields(updatedFields);
  }, [stepData]);

  const handleSubmit = async (values: StepSevenTypes) => {
    try {
      let payload = [
        {
          name: "germanLastEntry",
          label: "Have you ever been to Germany before your last entry?",
          value: values.germanLastEntry,
          isShow: true,
        },
        {
          name: "authorisation",
          label: "Authorisation to return to another country",
          value: values.authorisation,
          isShow: true,
        },
        {
          name: "residence",
          label:
            "Do you intend to maintain your permanent residence outside Germany?",
          value: values.residence,
          isShow: true,
        },
        {
          name: "maritalStatus",
          label: "Marital status",
          value: values.maritalStatus,
          isShow: true,
        },
        {
          name: "children",
          label: "Do you have children?",
          value: values.children,
          isShow: true,
        },
        {
          name: "father",
          label: "Do you know your father",
          value: values.father,
          isShow: true,
        },
        {
          name: "mother",
          label: "Do you know your mother",
          value: values.mother,
          isShow: true,
        },
        {
          name: "criminality",
          label: "Have you ever been convicted of a criminal offence?",
          value: values.criminality,
          isShow: true,
        },
        {
          name: "germanyRejection",
          label:
            "Have you been expelled or deported from the Federal Rep. of Germany, had an application for a visa or residence permit rejected, or been refused entry into the Federal Republic of Germany?",
          value: values.germanyRejection,
          isShow: true,
        },
        {
          name: "germanyRejectionReason",
          label: "Reason for rejection from germany",
          value: values.germanyRejectionReason,
          isShow: true,
        },
        {
          name: "otherRejection",
          label: "Reason for rejection",
          value: values.otherRejection,
          isShow: true,
        },
        {
          name: "otherRejectionReason",
          label: "Reason for rejection(exclude germany)",
          value: values.otherRejectionReason,
          isShow: true,
        },
        {
          name: "subsistence",
          label: "Do you have a Blocked Account",
          value: values.subsistence,
          isShow: true,
        },
        {
          name: "healthInsurance",
          label: "Do you have an adequate health insurance coverage",
          value: values.healthInsurance,
          isShow: true,
        },
        {
          name: "matriculationLetter",
          label:
            "Did you receive the matriculation letter from your university?",
          value: values.matriculationLetter,
          isShow: true,
        },

        {
          name: "date",
          label: "Enter the very first expected end date of your studies",
          value:
            values.date !== ""
              ? new Date(moment(values.date).format("YYYY/MM/DD")).toISOString()
              : values.date,
          isShow: true,
        },
      ];
      const uniqueNames: { [name: string]: boolean } = {};
      let updatedArr: stepDataType[] = [];
      payload.map((item) => {
        let itemName = item.name as keyof StepSevenTypes;
        const oldValue = stepFields[itemName];
        if (oldValue && oldValue !== item.value) {
          if (!uniqueNames[item.name]) {
            uniqueNames[item.name] = true;
            updatedArr.push({ ...item, value: item.value });
          }
        }
      });
      await submitStepdata(updatedArr?.length > 0 ? updatedArr : payload, null);
      if (
        values?.subsistenceFile &&
        typeof values?.subsistenceFile === "object"
      ) {
        const formData = new FormData();
        values?.subsistenceFile?.forEach((file: any) => {
          formData.append("subsistenceFile", file);
        });
        user && formData.append("userId", user?._id);
        currentStep && formData.append("step", (currentStep - 1).toString());
        formData.append(
          "label",
          "Upload proof of your Blocked Amount (if you do not have this, you can contact your bank and ask for it)"
        );
        await prestudyService.uploadDocument(formData);
      }
      if (
        values?.healthInsuranceFile &&
        !values?.healthInsuranceFile[0].hasOwnProperty("location")
      ) {
        const formData = new FormData();
        values?.healthInsuranceFile?.forEach((file: any) => {
          formData.append("healthInsuranceFile", file);
        });
        user && formData.append("userId", user?._id);
        currentStep && formData.append("step", (currentStep - 1).toString());
        formData.append(
          "label",
          "Upload a copy of your health insurance certificate (both sides of your insurance card OR contract OR confirmation from the insurance company)"
        );
        await prestudyService.uploadDocument(formData);
      }
      if (
        values?.matriculationFile &&
        typeof values?.matriculationFile === "object"
      ) {
        const formData = new FormData();
        values?.matriculationFile?.forEach((file: any) => {
          formData.append("matriculationFile", file);
        });
        user && formData.append("userId", user?._id);
        currentStep && formData.append("step", (currentStep - 1).toString());
        formData.append(
          "label",
          "Upload your matriculation letter from BSBI (if you do not have it, contact your university urgently)"
        );
        await prestudyService.uploadDocument(formData);
      }
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={stepSevenSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, handleChange }: any) => (
        <Form id={currentStep.toString()} className="w-full">
          <div className="border-[2px] border-[#E1E5EA] rounded-[8px] max-w-full bg-white p-4 h-full">
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Travels to Germany in the past
                </label>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"germanLastEntry"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Have you ever been to Germany before your last entry?
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="germanLastEntry">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="germanLastEntry"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(
                            "germanLastEntry",
                            event.target.value
                          );
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.germanLastEntry}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="germanLastEntry"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Authorisation to return to another country
                </label>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"authorisation"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Do you have any authorisation to return to a country which
                    is not your home country
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="authorisation">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="authorisation"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(
                            "authorisation",
                            event.target.value
                          );
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.authorisation}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="authorisation"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Residence outside Germany
                </label>
              </div>
              <div className="px-4 pt-4 text-[14px] text-[#71859A]">
                <p>
                  If you intend to study and stay in Germany and consider
                  Germany as your main residency, you should select NO.
                </p>
                <p>
                  Only in the case that you are in Germany for a foreseen short
                  period of time (for example not longer than 12-months) and you
                  intend to keep your apartment abroad in order to return, you
                  can select YES.
                </p>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"residence"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Do you intend to maintain your permanent residence outside
                    Germany?<span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="residence">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="residence"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue("residence", event.target.value);
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.residence}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="residence"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Marital Status
                </label>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"maritalStatus"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Marital Status{" "}
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="maritalStatus">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="maritalStatus"
                        options={maritalOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(
                            "maritalStatus",
                            event.target.value
                          );
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.maritalStatus}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="maritalStatus"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">Children</label>
              </div>
              <div className="px-4 pt-4 text-[14px] text-[#71859A]">
                <p>
                  On the next page you will need to provide information about
                  your children. Please enter the information as it is written
                  on your children's passports.{" "}
                </p>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"children"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Do you have children?
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="children">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="children"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue("children", event.target.value);
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.children}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="children"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">Father</label>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"father"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Do you know your father
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="father">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="father"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue("father", event.target.value);
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.father}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="father"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">Mother</label>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"mother"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Do you know your mother
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="mother">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="mother"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue("mother", event.target.value);
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.mother}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="mother"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">Criminality</label>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"criminality"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Have you ever been convicted of a criminal offence?
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="criminality">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="criminality"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue("criminality", event.target.value);
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.criminality}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="criminality"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Deportation or rejection from Germany
                </label>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"germanyRejection"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Have you been expelled or deported from the Federal Rep. of
                    Germany, had an application for a visa or residence permit
                    rejected, or been refused entry into the Federal Republic of
                    Germany?<span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="germanyRejection">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="germanyRejection"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(
                            "germanyRejection",
                            event.target.value
                          );
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.germanyRejection}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="germanyRejection"
                  component="div"
                  className="text-red-500"
                />
                <div className="flex flex-col my-2">
                  <p className="font-semibold text-[16px]  text-[#0B0D0F]">
                    If you know the reasons, describe here why you have had this
                    case.
                  </p>
                  <p className="font-semibold text-[16px]  text-[#0B0D0F]">
                    If you do not know the reasons, leave this field empty{" "}
                  </p>
                </div>
                <div className="lg:w-1/3 xl:w-1/3 w-full">
                  <Input
                    type="text"
                    fieldName="germanyRejectionReason"
                    placeholder=""
                    setValue={values.germanyRejectionReason}
                    className={null}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Deportation or rejection from the Schengen Area (excluding
                  Germany)
                </label>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"germanyRejection"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Have you been expelled or deported from another Schengen
                    state, had an application for a visa or residence permit
                    rejected, or been refused entry into the Schengen area?
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="otherRejection">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="otherRejection"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(
                            "otherRejection",
                            event.target.value
                          );
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.otherRejection}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="otherRejection"
                  component="div"
                  className="text-red-500"
                />
                <div className="flex flex-col my-2">
                  <p className="font-semibold text-[16px]  text-[#0B0D0F]">
                    If you know the reasons, describe here why you have had this
                    case.
                  </p>
                  <p className="font-semibold text-[16px]  text-[#0B0D0F]">
                    If you do not know the reasons, leave this field empty{" "}
                  </p>
                </div>
                <div className="lg:w-1/3 xl:w-1/3 w-full">
                  <Input
                    type="text"
                    fieldName="otherRejectionReason"
                    placeholder=""
                    setValue={values.otherRejectionReason}
                    className={null}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Means of subsistence
                </label>
              </div>
              <div className="px-4 pt-4 text-[14px] text-[#71859A]">
                <p>Means of subsistence </p>
                <p>
                  The issuance of a residence permit for students presupposes
                  that their livelihood is secure. The livelihood is to be
                  regarded as secured if the person concerned can secure their
                  livelihood, including health insurance, without using public
                  services.
                </p>
                <p>
                  A Blocked Account with a German bank for 11.208 Euros (2023)
                  is accepted as proof of secure livelihood. More information
                  can be found here:
                  https://www.auswaertiges-amt.de/en/sperrkonto/388600
                </p>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"subsistence"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Do you have a Blocked Account?
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="residence">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="subsistence"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue("subsistence", event.target.value);
                          if (event.target.value === "No") setfileError(null);
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.subsistence}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="subsistence"
                  component="div"
                  className="text-red-500"
                />
              </div>
              {values.subsistence === "Yes" && (
                <div className="px-4 py-2">
                  <p className="text-[#FF4444] text-[14px] font-semibold lg:w-3/4 xl:w-3/4 2xl:w-3/4 w-full">
                    You can also upload your blocked account from your HOME
                    COUNTRY. Therefore if you have a Blocked Account from your
                    HOME COUNTRY select Yes ATTENTION: BLOCKED ACCOUNT
                    CONFIRMATIONS FROM KOTAK ARE NOT ACCEPTED ANYMORE BY THE
                    FOREIGNERS OFFICE! You need to block the required amount in
                    another bank.
                  </p>
                  <div className="mb-2 mt-4 flex justify-between items-center">
                    <label
                      htmlFor={"subsistenceFile"}
                      className="font-400 text-[14px] text-[#0B0D0F]                    "
                    >
                      Upload proof of your Blocked Amount (if you do not have
                      this, you can contact your bank and ask for it)
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="subsistenceFile">
                    {({ field, form }: FieldProps) => (
                      <>
                        <Inputfile
                          {...field}
                          mainClass="lg:w-1/3 xl:w-1/3 w-full"
                          btnClass={null}
                          handleChange={(
                            event: ChangeEvent<HTMLInputElement>
                          ) => {
                            let selectedFile: FileList | null =
                              event.target.files;
                            selectedFile &&
                              form.setFieldValue(
                                "subsistenceFile",
                                Array.from(selectedFile)
                              );
                            setfileError(null);
                            uploadFiles(event.target.files, "subsistenceFile");
                          }}
                          multiple={false}
                        />
                        {typeof values.subsistenceFile === "string" && (
                          <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 overflow-hidden">
                            <img src={documentBlack} alt="documentBlack" />
                            <label className="flex-1">
                              {values.subsistenceFile}
                            </label>
                            <img
                              src={crossBlack}
                              alt="documentBlack"
                              onClick={() => {
                                form.setFieldValue("subsistenceFile", []);
                                setfileError("Files are required");
                              }}
                              className="cursor-pointer"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                  {files &&
                    files?.map((item) => (
                      <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 overflow-hidden">
                        <img src={documentBlack} alt="documentBlack" />
                        <label className="flex-1">{item.name}</label>
                        <img
                          src={crossBlack}
                          alt="documentBlack"
                          onClick={() =>
                            removeFile(item.name, "subsistenceFile")
                          }
                          className="cursor-pointer"
                        />
                      </div>
                    ))}
                  {fileError ? (
                    <div className="text-red-500 text-sm md:text-base fileError">
                      {fileError}
                    </div>
                  ) : (
                    <ErrorMessage
                      name="subsistenceFile"
                      component="div"
                      className="text-red-600 fileError"
                    />
                  )}
                </div>
              )}
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Health insurance
                </label>
              </div>
              <div className="px-4 pt-4 text-[14px] text-[#71859A] flex flex-col gap-5">
                <p>
                  The issue of a residence permit for students presupposes that
                  their livelihood is secure. The livelihood is to be regarded
                  as secured if the person concerned can secure their livelihood
                  including adequate health insurance coverage without using
                  public services.
                </p>
                <p>
                  For the issue of the residence permit in Germany, a contract
                  must be concluded that provides adequate health insurance
                  coverage, even if travel health insurance was sufficient for
                  entry. The residence permit is not issued without sufficient
                  proof of health insurance.
                </p>
                <p>
                  The health insurance can be a German statutory or private
                  health insurance.
                </p>
                <p>
                  With statutory health insurance coverage, sufficient health
                  insurance coverage can be assumed.
                </p>
                <p>
                  The type and scope of private health insurance coverage must
                  correspond to that of statutory health insurance, i.e. it may:
                </p>
                <p>- Not include service exclusions on a larger scale;</p>
                <p>
                  - Not require the insured person to pay a deductible higher
                  than 1,200 euros per year if the income, taking the deductible
                  into account, is sufficient to generate this deductible within
                  12 months without putting the security of the livelihood at
                  risk;
                </p>
                <p>
                  - Not have any limitation of the reimbursable costs in the
                  event of illness; and
                </p>
                <p>
                  - Not have expiry or expiry clauses with regard to a certain
                  age, the abandonment of an activity, a change of purpose or
                  the loss of a legal residence status.
                </p>
                <p>
                  Sufficient insurance protection is always to be assumed in the
                  basic tariff of private health insurance, as well as if the
                  Federal Financial Supervisory Authority has confirmed to the
                  insurance company that, based on the respective insurance
                  contract, the statutory requirements relating to health
                  insurance standardised in § 257 (2a) SGB V are met and the
                  health insurance certifies this.
                </p>
                <p>
                  If private health insurance does not meet these requirements,
                  adequate health insurance coverage is generally not to be
                  assumed, even if the insurance coverage otherwise corresponds
                  to the type and scope of statutory health insurance.
                </p>
                <p>
                  Private health insurance coverage must be valid for at least
                  one year from the moment of application of the residence
                  permit application (a very actual start of the ONE year
                  validity).
                </p>
                <p>
                  Regardless of age, proof of adequate insurance cover must also
                  be presented next to the matriculation certificate.
                </p>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"healthInsurance"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Do you have an adequate health insurance coverage
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="healthInsurance">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="healthInsurance"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(
                            "healthInsurance",
                            event.target.value
                          );
                          if (event.target.value === "No") {
                            setHealthfileError(null);
                          }
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.healthInsurance}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="healthInsurance"
                  component="div"
                  className="text-red-500"
                />
                {values.healthInsurance === "Yes" && (
                  <>
                    <div className="flex flex-col gap-2 text-[14px] my-2">
                      <p className=" text-[#71859A]">
                        Make sure that the contract or declaration meets the
                        requirements explained above.{" "}
                      </p>
                      <p className=" text-[#FF4444] font-semibold lg:w-3/4 xl:w-3/4 2xl:w-3/4 w-full">
                        Private health insurance coverage must be valid for at
                        least ONE YEAR from the moment of your residence permit
                        application (a very recent start of the ONE year
                        validity is required).{" "}
                      </p>
                    </div>
                    <div className="mb-2 mt-4 flex justify-between items-center">
                      <label
                        htmlFor={"healthInsuranceFile"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                      >
                        Upload a copy of your health insurance certificate (both
                        sides of your insurance card OR contract OR confirmation
                        from the insurance company)
                        <span className="text-[#FF4444] ml-1">*</span>
                      </label>
                    </div>
                    <Field name="healthInsuranceFile">
                      {({ field, form }: FieldProps) => (
                        <>
                          <Inputfile
                            {...field}
                            mainClass="lg:w-1/3 xl:w-1/3 w-full"
                            btnClass={null}
                            handleChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              let selectedFile: FileList | null =
                                event.target.files;
                              selectedFile &&
                                form.setFieldValue(
                                  "healthInsuranceFile",
                                  Array.from(selectedFile)
                                );
                              setHealthfileError(null);
                              uploadFiles(
                                event.target.files,
                                "healthInsuranceFile"
                              );
                            }}
                            multiple={true}
                          />
                          {!healthfileError && values.healthInsuranceFile &&
                            values.healthInsuranceFile.length > 0 &&
                            values.healthInsuranceFile.map(
                              (item: { name: string; location: string }) => (
                                <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 overflow-hidden">
                                  <img
                                    src={documentBlack}
                                    alt="documentBlack"
                                  />
                                  <label className="flex-1">{item.name}</label>
                                  <img
                                    src={crossBlack}
                                    alt="documentBlack"
                                    onClick={() => {
                                      const updatedFiles =
                                        values.healthInsuranceFile?.filter(
                                          (val: {
                                            name: string;
                                            location: string;
                                          }) => val.name !== item.name
                                        );
                                      form.setFieldValue(
                                        "healthInsuranceFile",
                                        updatedFiles
                                      );
                                      if (updatedFiles.length === 0) {
                                        form.setFieldValue(
                                          "healthInsuranceFile",
                                          []
                                        );
                                        setHealthfileError(
                                          "Files are required"
                                        );
                                      }
                                    }}
                                    className="cursor-pointer"
                                  />
                                </div>
                              )
                            )}
                        </>
                      )}
                    </Field>
                    {healthfileError ? (
                      <div className="text-red-500 text-sm md:text-base fileError">
                        {healthfileError}
                      </div>
                    ) : (
                      <ErrorMessage
                        name="healthInsuranceFile"
                        component="div"
                        className="text-red-600 fileError"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Study information
                </label>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"studyprogramme"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Select your study programme
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <SelectInput
                  fieldName="studyprogramme"
                  options={options}
                  className="lg:w-1/3 xl:w-1/3 w-full"
                  disabled={true}
                />
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Matriculation letter
                </label>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <label
                    htmlFor={"matriculationLetter"}
                    className="font-semibold text-[16px]  text-[#0B0D0F]"
                  >
                    Did you receive the matriculation letter from your
                    university?
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="matriculationLetter">
                  {({ field, form }: FieldProps) => (
                    <div className="flex flex-col gap-3 justify-center">
                      <RadioGroup
                        {...field}
                        fieldName="matriculationLetter"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(
                            "matriculationLetter",
                            event.target.value
                          );
                          if (event.target.value === "No") {
                            setMatriculationFileError(null);
                          }
                        }}
                        className={
                          "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                        }
                        checkedField={values.matriculationLetter}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="matriculationLetter"
                  component="div"
                  className="text-red-500"
                />
                {values.matriculationLetter === "Yes" && (
                  <>
                    <div className="my-2 flex justify-between items-center">
                      <label
                        htmlFor={"issueDate"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                      >
                        Enter the very first expected end date of your studies
                        <span className="text-[#FF4444] ml-1">*</span>
                      </label>
                    </div>
                    <Field name="date">
                      {({ field, form }: FieldProps) => (
                        <DateInput
                          {...field}
                          customInput={<CustomDateInput isCalendar={false} />}
                          dateFormat="yyyy-MM-dd"
                          selected={field.value}
                          onChange={(date: Date) => {
                            form.setFieldValue("date", date);
                          }}
                          className={"lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full"}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="date"
                      component="div"
                      className="text-red-600"
                    />
                    <p className="text-[#FF4444] text-[14px] font-semibold mt-1">
                      You can find the end date of your studies on your FIRST
                      matriculation letter.
                    </p>
                    <div className="mb-2 mt-4 flex justify-between items-center">
                      <label
                        htmlFor={"subsistenceFile"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                      >
                        Upload your matriculation letter from BSBI (if you do
                        not have it, contact your university urgently)
                        <span className="text-[#FF4444] ml-1">*</span>
                      </label>
                    </div>
                    <Field name="matriculationFile">
                      {({ field, form }: FieldProps) => (
                        <>
                          <Inputfile
                            {...field}
                            mainClass="lg:w-1/3 xl:w-1/3 w-full"
                            btnClass={null}
                            handleChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              let selectedFile: FileList | null =
                                event.target.files;
                              selectedFile &&
                                form.setFieldValue(
                                  "matriculationFile",
                                  Array.from(selectedFile)
                                );
                              setMatriculationFileError(null);
                              uploadFiles(
                                event.target.files,
                                "matriculationFile"
                              );
                            }}
                            multiple={false}
                          />
                          {typeof values.matriculationFile === "string" && (
                            <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 overflow-hidden">
                              <img src={documentBlack} alt="documentBlack" />
                              <label className="flex-1">
                                {values.matriculationFile}
                              </label>
                              <img
                                src={crossBlack}
                                alt="documentBlack"
                                onClick={() => {
                                  form.setFieldValue("matriculationFile", []);
                                  setMatriculationFileError(
                                    "Files are required"
                                  );
                                }}
                                className="cursor-pointer"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                    {matriculationFiles &&
                      matriculationFiles?.map((item) => (
                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 overflow-hidden">
                          <img src={documentBlack} alt="documentBlack" />
                          <label className="flex-1">{item.name}</label>
                          <img
                            src={crossBlack}
                            alt="documentBlack"
                            onClick={() =>
                              removeFile(item.name, "matriculationFile")
                            }
                            className="cursor-pointer"
                          />
                        </div>
                      ))}
                    {matriculationFileError ? (
                      <div className="text-red-500 text-sm md:text-base fileError">
                        {matriculationFileError}
                      </div>
                    ) : (
                      <ErrorMessage
                        name="matriculationFile"
                        component="div"
                        className="text-red-600 fileError"
                      />
                    )}
                  </>
                )}
              </div>
              {values.matriculationLetter === "Yes" && (
                <div className="flex flex-col gap-4 m-4">
                  <h3 className="text-[#0B0D0F] text-[18px]">
                    Example of matriculation letter{" "}
                  </h3>
                  <div className="w-3/4">
                    <img src={letter} alt="letter" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepSeven;

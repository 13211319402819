import * as Yup from "yup";
import { loginFieldsTypes, registerTypes } from "utils/types";

export const loginSchema: Yup.Schema<loginFieldsTypes> = Yup.object().shape({
  email: Yup.string()
    .email("Email address is invalid")
    .matches(
      /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+(?:com|co\.in)$/,
      "Email address is invalid"
    )
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password should be greater than 6 characters")
    .max(20, "Password should be less than 20 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).*$/,
      "Password must contain at least one uppercase letter, one digit, and one special character"
    ),
});

export const registerSchema: Yup.Schema<registerTypes> = Yup.object().shape({
  studentId: Yup.string()
    .required("Student ID is required")
    // .matches(/^[a-zA-Z0-9\s]+$/, "Please enter only digits and letters")
    // .test(
    //   "maxLength",
    //   "Student ID should not be greater than 10 characters",
    //   (value) => {
    //     const digitsOnly = value.replace(/\s/g, "");
    //     return digitsOnly.length <= 10;
    //   }
    // )
    // .test("contains-digits", "At least one digit is required", (value) => {
    //   return /\d/.test(value);
    // })
    // .test("contains-letters", "At least one letter is required", (value) => {
    //   return /[a-zA-Z]/.test(value);
    // })
    ,
  email: Yup.string()
    .email("Email address is invalid")
    .matches(
      /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+(?:com|co\.in)$/,
      "Email address is invalid"
    )
    .required("Email is required"),
  name: Yup.string()
    .required("Name is required")
    .max(35, "Name should be less than 35 characters")
    .test("contains-letters", "At least one letter is required", (value) => {
      return /[a-zA-Z]/.test(value);
    }),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .min(7, "Phone number should not less than 7 digits"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password should be greater than 6 characters")
    .max(20, "Password should be less than 20 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).*$/,
      "Password must contain at least one uppercase letter, one digit, and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Password must match")
    .required("Please confirm your password"),
  signature: Yup.string().required("Signature is required"),
  isAgree: Yup.boolean().oneOf([true], "Terms and conditions are required."),
});

export const ResetpasswordSchema: Yup.Schema<{
  password: string;
  confirmPassword: string;
}> = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password should be greater than 6 characters")
    .max(20, "Password should be less than 20 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).*$/,
      "Password must contain at least one uppercase letter, one digit, and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Password must match")
    .required("Please confirm your password"),
});

export const ForgetPwdSchema: Yup.Schema<{
  email: string;
}> = Yup.object().shape({
  email: Yup.string()
    .email("Email address is invalid")
    .matches(
      /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+(?:com|co\.in)$/,
      "Email address is invalid"
    )
    .required("Email is required"),
});

export const updateProfileSchema: Yup.Schema<{
  email: string;
  name: string;
  phoneNumber: string;
}> = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(35, "Name should be less than 35 characters")
    .matches(/^[A-Za-z\s]+$/, "Please enter only letters"),
  email: Yup.string()
    .required("Email address is required")
    .email("Email address is invalid")
    .matches(
      /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+(?:com|co\.in)$/,
      "Email address is invalid"
    )
    .required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .min(7, "Phone number should not less than 7 digits"),
});

import { Button } from "components/Form";
import { Dispatch, SetStateAction } from "react";
import { cross, user } from "utils/Images";

type modalPropTypes = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};
const ReminderModal = ({ setShowModal }: modalPropTypes) => {
  return (
    <div className="fixed inset-0 z-[9999999] flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg max-w-full w-[450px] md:w-[550px]  border-[2px] border-[#C3CCD5] lg:mx-0 mx-2 lg:mt-0 mt-[5.5rem]">
        <div className="modal-heading flex items-center justify-between mx-5 my-3">
          <h2 className="text-base md:text-xl font-bold text-[#0B0D0F] w-fit">
            Send Reminder
          </h2>
          <img
            src={cross}
            alt="cross"
            className="float-right cursor-pointer w-[35px] md:w-[40px]"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </div>
        <hr />
        <div className="chat-section m-5 flex flex-col gap-[10px] text-[14px]">
          <div className="flex w-2/3 ml-auto bg-[#F0F2F4] rounded-lg p-3 items-start">
            <label>
              Can you approve my registration as soon as possible? My visa will
              expire in 2 weeks
            </label>
            <img src={user} alt="profile-icon" />
          </div>
          <div className="flex w-2/3 mr-auto bg-[#F0F2F4] rounded-lg p-3 gap-2 items-start">
            <img src={user} alt="profile-icon" />
            <label>
              Please provide a better copy of your visa, the one you send is
              blurred
            </label>
          </div>
        </div>
        <div className="send-message m-5 text-[14px] flex flex-col">
          <label>Messages</label>
          <textarea
            name="messages"
            placeholder="Type a Messages..."
            rows={5}
            className="password-input border-[2px] border-[#E1E5EA] rounded-md placeholder-[#71859A] placeholder:text-[14px] w-full mt-1"
          />
          <Button
            type="submit"
            label="Send"
            disabled={false}
            cssClass="bg-[#0A4A5E] text-[15px] text-[#FFFFFF] py-2 w-[141px] rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed mt-5"
            // handleClick={currentStep > 1 ? handlePrevious : () => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default ReminderModal;

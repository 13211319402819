import React from "react";
import { downArrow } from "utils/Images";
import { useAppDispatch } from "store/Hooks";

type formStepType = {
  step: number;
  status: string;
  label: string;
};
type payloadType = {
  step: number;
  status: string;
};
type mobileStepperTypes = {
  isDropdownVisible: boolean;
  currentStep?: number;
  toogleDropdown: () => void;
  formStatus: formStepType[] | null;
  handleStep?: (paylod: payloadType) => any;
  userType?: string;
};
const MobileStepper: React.FC<mobileStepperTypes> = ({
  isDropdownVisible,
  currentStep,
  toogleDropdown,
  formStatus,
  handleStep,
  userType,
}) => {
  const dispatch = useAppDispatch();

  const handleChange = (index: number) => {
    if (handleStep) {
      dispatch(handleStep({ step: index + 1, status: "Inprogress" }));
      toogleDropdown();
    }
  };
  const setText = () => {
    if (currentStep) {
      if (currentStep === 1) return "Start";
      else if (userType === "lawyer" && currentStep === 9) return "Submit";
      else if (userType === "student" && currentStep === 10) return "Submit";
      else return `Step ${currentStep - 1}`;
    } else {
      return "Start"
    }
  };

  const getLabel = (data: formStepType) => {
    if (data.status === "completed") {
      return "text-[#E39D6B]";
    }
    if (data.status === "Inprogress") {
      return "text-[#0A4A5E]";
    }
    if (data.status === "pending") {
      if (data.step === 0) {
        return "text-[#0A4A5E]";
      } else {
        return "text-[#71859A]";
      }
    }
    if (data.status === "Skiped") {
      return "text-[#FF4444]";
    }
  };
  return (
    <>
      <div className="flex flex-col lg:hidden  border-[#C3CCD5] w-full text-red overflow-hidden fixed z-[9999]">
        <div className="flex justify-between items-center p-2 bg-white ">
          <span className="font-semibold">{setText()}</span>
          <img
            src={downArrow}
            alt="down-arrow"
            className="w-4 h-4 inline-block cursor-pointer"
            onClick={toogleDropdown}
          />
        </div>
        <div className="flex justify-end">
          {isDropdownVisible && (
            <ul className="p-4 font-semibold bg-white text-[14px] w-1/3 flex flex-col gap-2">
              {formStatus?.map((item: formStepType, index: number) => (
                <li
                  className={getLabel(item)}
                  key={index}
                  onClick={() => handleChange(index)}
                >
                  {["Start", "Submit"].includes(item.label)
                    ? item.label
                    : `Step ${item.label}`}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div
        className={`${
          isDropdownVisible
            ? "absolute top-0 right-0 left-0  bg-black w-full h-full z-50 opacity-50 block lg:hidden "
            : ""
        }`}
      ></div>
    </>
  );
};

export default MobileStepper;
